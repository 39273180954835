import React, { useState } from "react";
import { useAuth } from "./AuthContext";
import Swal from "sweetalert2";
import "./Profil.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setUser } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const loginResponse = await fetch("https://api.beerbuddy.fr/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (loginResponse.ok) {
        const loginData = await loginResponse.json();
        const token = loginData.token;
        localStorage.setItem("token", token); // Extraire le token

        // Requête pour obtenir les informations de l'utilisateur
        const userResponse = await fetch("https://api.beerbuddy.fr/currentUser", {
          headers: {
            Authorization: token, // Inclure le token dans l'en-tête
          },
        });

        if (userResponse.ok) {
          const userDetails = await userResponse.json();
          setUser({ user: userDetails }); // Mettre à jour l'état avec les détails de l'utilisateur

          Swal.fire("Succès!", "Connexion réussie", "success");
        } else {
          throw new Error("Impossible de récupérer les informations de l'utilisateur");
        }
      } else {
        throw new Error("Erreur de connexion");
      }
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
      Swal.fire("Erreur!", "Échec de la connexion", "error");
    }
  };

  return (
    <div>
      <form className="login-form" onSubmit={handleSubmit}>
        <h1 className="login-h1">Page de connexion</h1>
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="E-mail" />
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Mot de passe" />
        <button className="login-button" type="submit">
          Se connecter
        </button>
        <a href="/">Mot de passe oublié ?</a>
      </form>
    </div>
  );
}

export default Login;
