import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import axios from "axios";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "./Map.css";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

import markerIconPng from "leaflet/dist/images/marker-icon.png";
import markerIconShadow from "leaflet/dist/images/marker-shadow.png";

function App() {
  const [locations, setLocations] = useState([]);
  const [filter, setFilter] = useState("");

  const defaultIcon = L.icon({
    iconUrl: markerIconPng,
    shadowUrl: markerIconShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  L.Marker.prototype.options.icon = defaultIcon;

  useEffect(() => {
    axios
      .get("/data.json")
      .then((response) => {
        const loadedLocations = response.data
          .map((location) => {
            const { coordonneeLambertOrdonneeEtablissement, coordonneeLambertAbscisseEtablissement, trancheEffectifsUniteLegale } = location;
            const lat = parseFloat(coordonneeLambertAbscisseEtablissement);
            const lng = parseFloat(coordonneeLambertOrdonneeEtablissement);
            return !isNaN(lat) && !isNaN(lng) ? { ...location, lat, lng, descriptionEffectifs: getDescriptionTranche(trancheEffectifsUniteLegale) } : null;
          })
          .filter((location) => location !== null);
        setLocations(loadedLocations);
      })
      .catch((error) => console.log(error));
  }, []);

  function handleFilterChange(newFilter) {
    setFilter(newFilter);
  }

  function getDescriptionTranche(code) {
    const tranches = {
      NN: "Unités non employeuses",
      0: "0 salarié",
      1: "1 ou 2 salariés",
      "2": "3 à 5 salariés",
      3: "6 à 9 salariés",
      11: "10 à 19 salariés",
      12: "20 à 49 salariés",
      21: "50 à 99 salariés",
      22: "100 à 199 salariés",
      31: "200 à 249 salariés",
      32: "250 à 499 salariés",
      41: "500 à 999 salariés",
      42: "1 000 à 1 999 salariés",
      51: "2 000 à 4 999 salariés",
      52: "5 000 à 9 999 salariés",
      53: "10 000 salariés et plus",
    };
    return tranches[code] || "Information non disponible";
  }

  const filteredLocations = locations.filter((location) => {
    const locationEffectif = location.trancheEffectifsUniteLegale.toString(); // Convertit en chaîne si ce n'est pas déjà le cas
    const currentFilter = filter.toString(); // Convertit en chaîne si ce n'est pas déjà le cas
    return currentFilter === "" || locationEffectif === currentFilter;
  });

  return (
    <div>
      <div className="SelectEffectif">
        <FormControl fullWidth>
          <InputLabel id="filter-select-label">Effectifs</InputLabel>
          <Select labelId="filter-select-label" id="filter-select" value={filter} label="Effectifs" onChange={(e) => handleFilterChange(e.target.value)}>
            <MenuItem value="">Tous les effectifs</MenuItem>
            <MenuItem value="1">1-2 salariés</MenuItem>
            <MenuItem value="2">3-5 salariés</MenuItem>
            <MenuItem value="3">6-9 salariés</MenuItem>
            <MenuItem value="11">10-19 salariés</MenuItem>
            <MenuItem value="12">20-49 salariés</MenuItem>
            <MenuItem value="21">50-99 salariés</MenuItem>
            <MenuItem value="22">100-199 salariés</MenuItem>
            <MenuItem value="31">200-249 salariés</MenuItem>
            <MenuItem value="32">250-499 salariés</MenuItem>
            <MenuItem value="41">500-999 salariés</MenuItem>
            <MenuItem value="42">1000-1999 salariés</MenuItem>
          </Select>
        </FormControl>
      </div>
      <MapContainer className="MapContainer" center={[46.2276, 2.2137]} zoom={8}>
        <TileLayer url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png" />
        {filteredLocations.map((location, index) => (
          <Marker key={index} position={[location.lat, location.lng]} icon={defaultIcon}>
            <Popup>
              <b>{location.denominationUniteLegale || location.denominationUsuelleEtablissement || "Inconnu"}</b>
              <br />
              Effectifs: {location.descriptionEffectifs}
              <br />
              SIRET: {location.siret}
              <br />
              Ville: {location.Ville || "Non spécifié"}
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
}

export default App;
