import React, { useState, useEffect } from "react";
import { useAuth } from "./AuthContext";

import DeconnexionComponent from "./DeconnexionComponent";
import "./Profil.css";

import RequiredAuth from "../Auth/RequiredAuth";

function Profil() {
  const [tastingNoteCount, setTastingNoteCount] = useState(0);
  const [myTastingNotes, setMyTastingNotes] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (token) {
          const fetchOptions = {
            headers: {
              Authorization: token, // Inclure le token JWT
            },
          };
          // Première requête pour myTastingNotes
          const notesResponse = await fetch(
            "https://api.beerbuddy.fr/myTastingNotes",
            fetchOptions
          );
          if (!notesResponse.ok)
            throw new Error("Requête myTastingNotes a échoué");
          const notesData = await notesResponse.json();
          setMyTastingNotes(notesData);

          // Deuxième requête pour userTastingNoteCount
          const countResponse = await fetch(
            "https://api.beerbuddy.fr/userTastingNoteCount",
            fetchOptions
          );
          if (!countResponse.ok)
            throw new Error("Requête userTastingNoteCount a échoué");
          const countData = await countResponse.json();
          setTastingNoteCount(countData.count);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };

    fetchData();
  }, [user]);

  // Si l'utilisateur n'est pas connecté, affichez un message approprié
  if (!user || !user.user) {
    return <RequiredAuth />;
  }

  const userDetails = user.user;

  // Maintenant, vérifiez que les champs nécessaires sont présents
  if (!userDetails.email || !userDetails.nom) {
    return <p>Des données utilisateur sont manquantes.</p>;
  }

  return (
    <div className="profile-container">
      <h1>Page de profil</h1>
      <p>
        Bonjour, {userDetails.prenom}, {userDetails.nom}!
      </p>
      <p>Voici quelques détails sur votre profil:</p>
      <ul>
        <li>Nombre de dégustations : {tastingNoteCount}</li>
        <li>Nom d'utilisateur: {userDetails.nom}</li>
        <li>Email: {userDetails.email}</li>
        <DeconnexionComponent />
      </ul>
    </div>
  );
}

export default Profil;
