import React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useAuth } from "./AuthContext"; // Assurez-vous que c'est le bon chemin d'import
import { FiLogOut } from "react-icons/fi";
import "./DeconnexionComponent.css";

function DeconnexionComponent() {
  const navigate = useNavigate();
  const { setUser } = useAuth(); // Utilisation de setUser pour effacer les données de l'utilisateur

  const handleDeconnexion = async () => {
    try {
      // Appel de l'API pour gérer la déconnexion côté serveur.
      const response = await fetch("https://api.beerbuddy.fr/logout", {
        method: "POST",
        credentials: "include", // Assurez-vous d'envoyer les cookies si nécessaire
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Erreur lors de la déconnexion");
      }

      // Nettoyez le contexte utilisateur
      setUser(null);

      // Nettoyez les tokens d'authentification stockés localement, si vous en utilisez
      localStorage.removeItem("token"); // Exemple si vous stockez un token

      // Redirigez vers la page de connexion ou d'accueil
      navigate("/");

      // Affichez une alerte indiquant que la déconnexion a été réussie
      Swal.fire({
        title: "Déconnecté",
        text: "Vous avez été déconnecté avec succès.",
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (error) {
      console.error("Erreur de déconnexion:", error);
      Swal.fire({
        title: "Erreur!",
        text: "Erreur lors de la déconnexion.",
        icon: "error",
        confirmButtonText: "Réessayer",
      });
    }
  };

  return (
    <button className="DeconnexionComponent-button" onClick={handleDeconnexion}>
      <FiLogOut className="DecoIcon" />
    </button>
  );
}

export default DeconnexionComponent;
