import * as React from "react"
const SymboleGout = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Calque_1"
    data-name="Calque 1"
    viewBox="0 0 93.78 108.65"
    {...props}
  >
    <defs>
      <style>
        {
          ".ROUGE1{fill:#e6332a}.ROUGE{fill:#ca2423}.ROUGE3{fill:#1d1d1b}.ROUGE5{fill:#e05753}"
        }
      </style>
    </defs>
    <g id="Bouche">
      <g id="Bouche_couleurs" data-name="Bouche couleurs">
        <path
          d="M78.6 59.82a61.2 61.2 0 0 1-4.86 20.83 59 59 0 0 0 3-14.39 38.81 38.81 0 0 0-.22-9 25.34 25.34 0 0 0-1.13-4.52 17.09 17.09 0 0 0-8.71-10c-4.15-1.85-9.46-1.32-12.7 1.87-3 3-3.81 7.51-4.32 11.72-1.08 8.71-1.74 17.46-2.31 26.23-.16 2.5-.31 5-.47 7.51a74.74 74.74 0 0 1-.61-8.12c-.07-2.77 0-5.54 0-8.25.17-5.67.82-11.39-.11-17s-3.82-11.22-8.88-13.78a13.31 13.31 0 0 0-13.73 1.3c-4.14 3.18-5.95 8.46-6.72 13.7-.09.6-.16 1.2-.22 1.8a58.65 58.65 0 0 0-.32 5.29 48 48 0 0 1-.48-5.23v-3a26.29 26.29 0 0 1 2-9.61c3.55-7.92 11.94-11.62 27-5.89a5.22 5.22 0 0 0 .56.18 6.12 6.12 0 0 0 1.71.2h.07a6.12 6.12 0 0 0 1.71-.2 5.22 5.22 0 0 0 .56-.18c15.1-5.73 23.49-2 27 5.89a25.15 25.15 0 0 1 2 8.31 38 38 0 0 1 .18 4.34Z"
          className="ROUGE1"
        />
        <path
          d="M56.76 3c-5.6 0-6.26 2.47-9.52 2.56h-.07C43.91 5.47 43.25 3 37.65 3c-6.3 0-17.27 9.8-34.25 36.31a100 100 0 0 0 12.46 17.52 26.29 26.29 0 0 1 2-9.61 32.36 32.36 0 0 1-8.14-7.65c6.7-16.15 46-46.82 75 0a32.36 32.36 0 0 1-8.14 7.65 25.15 25.15 0 0 1 2 8.31c4.68-4.44 7.51-9.15 12.5-16.22C74.03 12.8 63.06 3 56.76 3Zm13.1 13C65.46 12.74 60 4.28 52.34 8.34c-9 4.77-13.13-4.21-22.18 3.7-7.39 6.47-10.94 6.8-5.95 1.58s10.74-11 17.29-8c9.08 4.09 13.05-5.73 20.69.31a47.26 47.26 0 0 1 9.74 10.17c.69.85 2.12 2.97-2.07-.14Z"
          className="ROUGE1"
        />
        <path
          d="M15.86 59.82c-9.3-8.1-12.17-18.15-12.41-20.51a100 100 0 0 0 12.41 17.52c-.08.98-.08 1.97 0 2.99ZM84.69 39.57c-5-7.12-12.72-12.58-19.31-16.18a66.91 66.91 0 0 0-8.7-4 9 9 0 0 0-4.33-.6 31.32 31.32 0 0 1-5.1.25h-.07a31.32 31.32 0 0 1-5.1-.25 9 9 0 0 0-4.33.6 66.91 66.91 0 0 0-8.7 4c-6.59 3.6-14.32 9.06-19.31 16.18 6.68-16.15 45.97-46.82 74.95 0ZM91.01 39.31c-.24 2.36-3.11 12.41-12.41 20.51a38 38 0 0 0-.09-4.29c4.68-4.44 7.51-9.15 12.5-16.22Z"
          className="ROUGE"
        />
        <path
          d="M84.69 39.57a32.36 32.36 0 0 1-8.14 7.65c-3.55-7.92-11.94-11.62-27-5.89a5.22 5.22 0 0 1-.56.18 6.12 6.12 0 0 1-1.71.2h-.07a6.12 6.12 0 0 1-1.71-.2 5.22 5.22 0 0 1-.56-.18c-15.1-5.73-23.49-2-27 5.89a32.36 32.36 0 0 1-8.14-7.65c5-7.12 12.72-12.58 19.31-16.18 4.74 2.05 9.7 2.62 18.13 2.45h.07c8.43.17 13.39-.4 18.13-2.45 6.53 3.6 14.26 9.06 19.25 16.18Z"
          className="ROUGE3"
        />
        <path
          d="M316.51 393.17c-4.74 2.05-9.7 2.62-18.13 2.45h-.07c-8.43.17-13.39-.4-18.13-2.45a66.91 66.91 0 0 1 8.7-4 9 9 0 0 1 4.33-.6 31.32 31.32 0 0 0 5.1.25h.07a31.32 31.32 0 0 0 5.1-.25 9 9 0 0 1 4.33.6 66.91 66.91 0 0 1 8.7 4Z"
          style={{
            fill: "#f2f0f0",
          }}
          transform="translate(-251.14 -369.78)"
        />
        <path
          d="M69.86 15.96C65.46 12.7 60 4.24 52.34 8.3c-9 4.77-13.13-4.21-22.18 3.7-7.39 6.47-10.94 6.8-5.95 1.58s10.74-11 17.29-8c9.08 4.09 13.05-5.73 20.69.31a47.26 47.26 0 0 1 9.74 10.17c.69.89 2.12 3.01-2.07-.1Z"
          className="ROUGE5"
        />
        <path
          d="M327.66 427.09a25.34 25.34 0 0 0-1.13-4.52 17.09 17.09 0 0 0-8.71-10c-4.15-1.85-9.46-1.32-12.7 1.87-3 3-3.81 7.51-4.32 11.72-1.08 8.71-1.74 17.46-2.31 26.23-.16 2.5-.31 5-.47 7.51a74.74 74.74 0 0 1-.61-8.12c-.07-2.77 0-5.54 0-8.25.17-5.67.82-11.39-.11-17s-3.82-11.22-8.88-13.78a13.31 13.31 0 0 0-13.73 1.25c-4.14 3.18-5.95 8.46-6.72 13.7-.09.6-.16 1.2-.22 1.8a58.65 58.65 0 0 0-.32 5.29c2.7 18.92 16.07 41 30.88 40.61h.07c7.13.18 13.92-4.83 19.35-12.19a62 62 0 0 0 7.1-12.68v-.11a59 59 0 0 0 3-14.39 38.81 38.81 0 0 0-.17-8.94Zm-32.44 35.13c0 1.34.13 3-.76 4.14a4.1 4.1 0 0 1-3.74 1.58 8.7 8.7 0 0 1-4.22-2.32 47.69 47.69 0 0 1-15.74-27c-.14-.71-.26-1.43-.37-2.15a30.3 30.3 0 0 1-.13-8.18 19.45 19.45 0 0 1 3.85-9.51 12.26 12.26 0 0 1 5.8-4.2 8.3 8.3 0 0 1 7 .8 10.67 10.67 0 0 1 3.93 5.79 43.22 43.22 0 0 1 1.6 9.06q1 9.93 2.08 19.86v.22a87.24 87.24 0 0 1 .7 11.91Zm19.1-27.22c-2.66 7.27-4.78 14.84-7.36 22.16-.9 2.58-1.86 5.13-2.92 7.62a1.39 1.39 0 0 1-1.16.87c-2.24.13-2.78-5.12-2.47-12.19.59-13.06 4.1-32.34 5.29-35.73 1.65-4.71 6.87-5.57 9.39-.88 3.22 5.65 1.22 12.43-.77 18.15Z"
          style={{
            fill: "#e04848",
          }}
          transform="translate(-251.14 -369.78)"
        />
        <path
          d="M63.18 65.22c-2.66 7.27-4.78 14.84-7.36 22.16-.9 2.58-1.86 5.13-2.92 7.62a1.39 1.39 0 0 1-1.16.87c-2.24.13-2.78-5.12-2.47-12.19.59-13.06 4.1-32.34 5.29-35.73 1.65-4.71 6.87-5.57 9.39-.88 3.22 5.65 1.22 12.43-.77 18.15ZM44.08 92.44c0 1.34.13 3-.76 4.14a4.1 4.1 0 0 1-3.74 1.58 8.7 8.7 0 0 1-4.22-2.32 47.69 47.69 0 0 1-15.74-27c-.14-.71-.26-1.43-.37-2.15a30.3 30.3 0 0 1-.13-8.18A19.45 19.45 0 0 1 22.97 49a12.26 12.26 0 0 1 5.8-4.2 8.3 8.3 0 0 1 7 .8 10.67 10.67 0 0 1 3.93 5.79 43.22 43.22 0 0 1 1.6 9.06q1 9.93 2.08 19.86v.22a87.24 87.24 0 0 1 .7 11.91Z"
          className="ROUGE5"
        />
      </g>
      <path
        id="Bouche_contours_ext\xE9rieurs"
        d="M307.58 372.77c6.31 0 17.28 9.82 34.26 36.32-.24 2.35-3.11 12.41-12.41 20.51a61.36 61.36 0 0 1-4.86 20.84s0 .08 0 .12a62.16 62.16 0 0 1-7.1 12.67c-5.34 7.23-12 12.2-19 12.2h-.81c-14.67 0-27.85-21.85-30.52-40.62a48 48 0 0 1-.48-5.23c-9.3-8.1-12.17-18.16-12.41-20.51 17-26.5 28-36.32 34.26-36.32 5.59 0 6.24 2.48 9.51 2.57h.08c3.25-.09 3.9-2.57 9.5-2.57m0-3a14.69 14.69 0 0 0-7.54 1.86 3.19 3.19 0 0 1-4 0 14.69 14.69 0 0 0-7.54-1.86c-8.5 0-20.53 12.33-36.78 37.7l-.57.88.11 1c.31 3 3.28 13.1 12.47 21.59.08 1.38.23 2.8.44 4.26a69.46 69.46 0 0 0 11.27 28.64c6.56 9.38 14.45 14.55 22.21 14.55h.81c7.39 0 15-4.76 21.37-13.41a64.26 64.26 0 0 0 7.45-13.29 1.21 1.21 0 0 1 .05-.14v-.07a65 65 0 0 0 5-20.48c9.19-8.49 12.15-18.62 12.47-21.59l.11-1-.57-.88c-16.25-25.37-28.28-37.7-36.78-37.7Z"
        data-name="Bouche contours ext\xE9rieurs"
        style={{
          fill: "#fff",
        }}
        transform="translate(-251.14 -369.78)"
      />
      <g
        id="Bouche_contours_int\xE9rieurs"
        data-name="Bouche contours int\xE9rieurs"
      >
        <path
          d="M46.54 106.04c-14.83 0-28.15-21.44-30.93-40.94a50.31 50.31 0 0 1-.48-5.27v-3a26.42 26.42 0 0 1 2.07-9.75c2.89-6.44 10-12.73 27.51-6.09a3.16 3.16 0 0 0 .52.17 7.27 7.27 0 0 0 3.26 0 4.4 4.4 0 0 0 .57-.17c17.5-6.65 24.62-.36 27.51 6.08a25.16 25.16 0 0 1 2 8.44 38.32 38.32 0 0 1 .09 4.32 61.89 61.89 0 0 1-4.88 21l-.05.13a62.64 62.64 0 0 1-7.15 12.76c-6 8.13-13 12.48-19.66 12.35Zm-15-67.12c-6.51 0-11.16 2.83-13.68 8.46a25.52 25.52 0 0 0-2 9.47v2.95a46.46 46.46 0 0 0 .47 5.2c2.75 19.31 16.36 40.65 30.5 40.29h.08c6.42.16 13.2-4.12 19-12a61.9 61.9 0 0 0 7.07-12.6l.05-.15a61 61 0 0 0 4.82-20.7 37 37 0 0 0-.09-4.24 24.4 24.4 0 0 0-1.92-8.18c-4-8.82-13.14-10.79-26.57-5.7a3.82 3.82 0 0 1-.6.19 6.65 6.65 0 0 1-1.79.22h-.02a7.05 7.05 0 0 1-1.75-.2 4.29 4.29 0 0 1-.66-.21 37.14 37.14 0 0 0-12.88-2.8Z"
          className="ROUGE3"
        />
        <path
          d="M77.86 60.66v-.86a37 37 0 0 0-.09-4.24 24.4 24.4 0 0 0-1.92-8.18l-.13-.29.26-.17a33.52 33.52 0 0 0 7.9-7.34c-5.23-7.35-13.17-12.65-19-15.82h-.08a66.53 66.53 0 0 0-8.66-4 8.5 8.5 0 0 0-4.14-.58 30.75 30.75 0 0 1-5.18.25 30.15 30.15 0 0 1-5.21-.26 8.64 8.64 0 0 0-4.14.59 66.53 66.53 0 0 0-8.66 4l-.08.05c-5.8 3.16-13.73 8.46-19 15.81a33.52 33.52 0 0 0 7.9 7.34l.26.18-.13.28a25.52 25.52 0 0 0-2 9.47v3.81l-.65-.56C5.62 51.88 2.8 41.67 2.57 39.39v-.13l.07-.11C17.94 15.28 29.89 2.66 37.21 2.66a12 12 0 0 1 6.36 1.57 5.65 5.65 0 0 0 6.39 0 12 12 0 0 1 6.36-1.57c7.32 0 19.27 12.62 34.57 36.49l.07.11v.13c-.23 2.28-3 12.49-12.54 20.75ZM3.45 39.4c.31 2.39 3 11.73 11.64 19.57v-2.15a26.88 26.88 0 0 1 1.94-9.47 32.59 32.59 0 0 1-8-7.58l-.13-.21.14-.21c5.34-7.61 13.52-13.05 19.44-16.28l.09-.05a65.2 65.2 0 0 1 8.68-4 9.09 9.09 0 0 1 4.5-.62 31.2 31.2 0 0 0 5 .25h.09a31.35 31.35 0 0 0 5.05-.25 9.09 9.09 0 0 1 4.5.62 65.2 65.2 0 0 1 8.68 4l.09.05c5.92 3.23 14.1 8.67 19.44 16.28l.14.21-.13.21a32.5 32.5 0 0 1-8 7.58 25.42 25.42 0 0 1 1.87 8.16c.09 1.1.13 2.26.11 3.46 8.63-7.84 11.33-17.18 11.63-19.57-19.08-29.77-28.95-36-33.86-36a11.46 11.46 0 0 0-6 1.48 7.45 7.45 0 0 1-3.5 1.08 7.46 7.46 0 0 1-3.51-1.08 11.46 11.46 0 0 0-6-1.48c-4.92-.03-14.79 6.23-33.88 36Z"
          className="ROUGE3"
        />
        <path
          d="m76.08 47.79-.18-.41c-4-8.82-13.14-10.79-26.57-5.7a3.82 3.82 0 0 1-.6.19 6.65 6.65 0 0 1-1.79.22h-.08a7.05 7.05 0 0 1-1.75-.2 4.29 4.29 0 0 1-.66-.21c-13.42-5.09-22.6-3.12-26.56 5.7l-.19.41-.37-.26a33.06 33.06 0 0 1-8.24-7.76l-.13-.21.14-.21c5.34-7.61 13.52-13.05 19.44-16.28l.09-.05h.24c4.55 2 9.25 2.59 18 2.42h.08c8.74.17 13.44-.46 18-2.42H65.19l.09.05c5.92 3.23 14.1 8.67 19.44 16.28l.14.21-.13.21a33.06 33.06 0 0 1-8.24 7.76ZM9.86 39.58a32.74 32.74 0 0 0 7.54 7.08c3-6.3 10.19-12.18 27.32-5.68a3.16 3.16 0 0 0 .52.17 7.27 7.27 0 0 0 3.26 0 4.4 4.4 0 0 0 .57-.17c17.13-6.51 24.31-.63 27.32 5.67a32.74 32.74 0 0 0 7.54-7.08c-5.21-7.32-13.1-12.6-18.88-15.77-4.6 2-9.38 2.58-18.13 2.4-8.8.18-13.58-.45-18.18-2.4-5.78 3.18-13.67 8.42-18.88 15.78Z"
          className="ROUGE3"
        />
        <path
          d="M49.54 26.22h-2.62c-8.9.18-13.69-.47-18.34-2.47l-.71-.31.68-.36a65.58 65.58 0 0 1 8.76-4 9.09 9.09 0 0 1 4.5-.62 31.2 31.2 0 0 0 5 .25h.09a31.35 31.35 0 0 0 5.05-.25 9.09 9.09 0 0 1 4.5.62 65.58 65.58 0 0 1 8.76 4l.68.36-.71.31c-4.14 1.78-8.42 2.47-15.64 2.47ZM29.6 23.33c4.33 1.72 9 2.28 17.25 2.12h.08c8.28.16 12.93-.4 17.26-2.12a63.63 63.63 0 0 0-8-3.6 8.5 8.5 0 0 0-4.14-.58 30.75 30.75 0 0 1-5.18.25 30.15 30.15 0 0 1-5.21-.26 8.64 8.64 0 0 0-4.14.59 63.63 63.63 0 0 0-7.92 3.62Z"
          className="ROUGE3"
        />
      </g>
    </g>
  </svg>
)
export default SymboleGout;
