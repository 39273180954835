import React, { createContext, useState, useContext, useEffect } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Récupérer le token JWT depuis le stockage local lors de l'initialisation
    const token = localStorage.getItem("token");

    if (token) {
      // Mettre à jour l'état de l'utilisateur comme connecté avec le token
      setUser({ loggedIn: true, token: token });

      // Effectuer une requête au backend pour obtenir les informations de l'utilisateur
      fetch("https://api.beerbuddy.fr/currentUser", {
        method: "GET",
        headers: {
          Authorization: token, // Inclure le token dans l'en-tête
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              "Échec de la récupération des informations de l'utilisateur"
            );
          }
          return response.json();
        })
        .then((userData) => {
          // Mettre à jour l'état de l'utilisateur avec les informations du backend
          setUser({ loggedIn: true, token: token, user: userData });
        })
        .catch((error) => {
          console.error(
            "Erreur lors de la récupération des informations de l'utilisateur :",
            error
          );
        });
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
