import React from 'react';
import Lottie from 'lottie-react';
import Swal from 'sweetalert2';
import animationData from '../Information_custom_icon.json'; 
import './InformationIcon.css';

const InformationIconCarbo = () => {

  const handleClick = () => {
    Swal.fire({
      title: 'Information',
      icon: 'info',
      html: `
      <strong>Carbonatation</strong>: La carbonatation fait référence à la présence de dioxyde de carbone (CO<sub>2</sub>) dissous dans la bière, résultant généralement de la fermentation. Ce CO<sub>2</sub> se libère sous forme de bulles lorsque la bière est versée ou bue.<br>
      <strong>Faible carbonatation</strong>: Une bière avec une faible carbonatation aura très peu de bulles et peut donner une sensation plus douce en bouche. Elle est courante dans certains styles traditionnels, comme les bières anglaises servies à partir de pompes manuelles.<br>
      <strong>Carbonatation moyenne</strong>: C'est le niveau de carbonatation le plus courant pour la majorité des bières. Elle offre un équilibre entre une sensation rafraîchissante et la mise en valeur des arômes de la bière.<br>
      <strong>Haute carbonatation</strong>: Une forte carbonatation donne une sensation pétillante et effervescente en bouche, similaire à celle d'une boisson gazeuse. Elle est typique des bières belges comme les Saisons ou les Tripels, ainsi que des bières de fermentation spontanée comme les Lambics.
    `,
    confirmButtonText: 'Compris!'
  });
    
  };

  return (
    <div className='icon' onClick={handleClick}>
      <Lottie animationData={animationData} />
    </div>
  );
};

export default InformationIconCarbo;
