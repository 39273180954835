import * as React from "react"
const SymboleOdorat = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Calque_1"
    data-name="Calque 1"
    viewBox="0 0 62.88 95.78"
    {...props}
  >
    <defs>
      <style>{".cls-2{fill:#d88c6a}.cls-3{fill:#883026}"}</style>
    </defs>
    <g id="Nez">
      <g id="Nez_couleurs" data-name="Nez couleurs">
        <path
          d="M272.93 435.72c-.08-2 .2-4.05.05-6.11-.26-3.37-.86-6.69-1.31-10-.66-4.85-1.37-9.7-1.9-14.58a120.36 120.36 0 0 1-.85-17.29 24.5 24.5 0 0 1 1.22-6.94c.77-2.23 2.63-3.32 4.62-4.27a15.34 15.34 0 0 1 10.68-.91 5.18 5.18 0 0 1 4 4.58c-.59.07-.47.53-.46.9a10.62 10.62 0 0 0 .52 3c3 9.76 8.72 18.07 14.54 26.27 3.46 4.86 7.76 9 11.86 13.36a2.46 2.46 0 0 0 .58 1.39 51.18 51.18 0 0 1 2.94 5.08 5 5 0 0 1-.34 5.75 10.74 10.74 0 0 1-8.31 4 28.45 28.45 0 0 0-12.77 3.68c-2.64 1.45-5 3.29-7.73 4.65s-5.55 2.39-8.66 2.09a6.33 6.33 0 0 1-4.11-2c-1.71-1.82-2.23-4.08-2.76-6.39s-.9-4.82-1.44-7.4c-.25.47-.05.9-.37 1.14Zm41.07-5.18a14.61 14.61 0 0 0-.4-2.94 19.2 19.2 0 0 0-2.66-5.9c-1.15-1.91-2.67-3.57-4-5.37a94.93 94.93 0 0 1-5.54-7.91c-2-3.48-4.25-6.83-6.32-10.28-1.36-2.26-2.55-4.62-3.79-6.95a50.87 50.87 0 0 1-3.59-8.18 9.9 9.9 0 0 0-2.56-4.25c-2.25-2.14-5.7-.92-6.8.95-1.34 2.27-1.12 4.58-.59 6.94s1.92 4.41 3 6.57c1.49 3 3.12 6 4.62 9.11a87.15 87.15 0 0 1 3.35 8.53 11.66 11.66 0 0 1 .72 3.32c.27 4.25-1.85 7.66-3.87 11.09a59.53 59.53 0 0 0-3.47 6.32 22.45 22.45 0 0 0-1.82 6.13c-.57 4.72 1.11 7.92 6.48 7.92a13.33 13.33 0 0 0 8.42-3.15 31.58 31.58 0 0 1 14.13-6.09c1-.19 2.15-.16 3-1.14a6.11 6.11 0 0 0 1.69-4.72Z"
          style={{
            fill: "#edb091",
          }}
          transform="translate(-266.42 -372.59)"
        />
        <path
          d="M6.51 63.13c.32-.24.12-.67.39-1.15.54 2.58.88 5 1.44 7.4s1.05 4.57 2.76 6.39a6.33 6.33 0 0 0 4.11 2c3.11.3 5.91-.71 8.66-2.09s5.09-3.2 7.73-4.65a28.45 28.45 0 0 1 12.74-3.62 10.74 10.74 0 0 0 8.31-4 5 5 0 0 0 .34-5.75 51.18 51.18 0 0 0-2.94-5.08 2.46 2.46 0 0 1-.58-1.39 13.35 13.35 0 0 1 2.28 2.35c2.47 2.86 4.89 5.76 6 9.47a7.5 7.5 0 0 1-2.56 8.63c-3.19 2.53-6.79 4.39-10.2 6.56-3.13 2-6.32 3.89-8.42 7.06-.94 1.42-1.68 3-1 4.76-.12.93-.92 1.22-1.59 1.57a18.56 18.56 0 0 1-8 1.8 26.36 26.36 0 0 1-11.03-1.98c-5.45-2.23-8.42-6.58-9.88-12.1a10.93 10.93 0 0 1-.37-4.89c.46.19.3.6.32.92a14.15 14.15 0 0 0 3.14 8.33 1.59 1.59 0 0 0 .6 1.23 11.69 11.69 0 0 0 7.79 3.07 24.81 24.81 0 0 0 8.22-1 18 18 0 0 0 6.08-2.95c3.9-3.07 7.08-6.95 11.08-9.92.81-.6.57-1-.24-1.24a12.37 12.37 0 0 0-4.88-.38 22.54 22.54 0 0 0-10.18 4c-3 1.93-5.77 4.11-9 5.58-4.27 1.95-7.67 1.26-9.89-3.08a26.63 26.63 0 0 1-1.88-5.31c-.15-.55-.24-1-1-.79.39-2 .81-4 1.15-6 .25-1.24.35-2.47.5-3.75Z"
          className="cls-2"
        />
        <path
          d="M35.58 89.95c-.65-1.8.09-3.34 1-4.76 2.1-3.17 5.29-5.09 8.42-7.06 3.41-2.17 7-4 10.2-6.56a7.5 7.5 0 0 0 2.56-8.63c-1.15-3.71-3.57-6.61-6-9.47a13.35 13.35 0 0 0-2.28-2.35c-4.1-4.33-8.4-8.5-11.86-13.36-5.82-8.2-11.51-16.51-14.54-26.27a10.62 10.62 0 0 1-.52-3c0-.37-.13-.83.46-.9.3 1.16.54 2.35.9 3.49a59.6 59.6 0 0 0 6.47 13.07 131.5 131.5 0 0 0 21.45 26.39 33.94 33.94 0 0 1 7.05 9.25c1.52 3.07 2.12 6.24.85 9.57a10.58 10.58 0 0 1-3.92 4.46 87.63 87.63 0 0 1-9.93 5.89 42 42 0 0 0-5.94 3.82c-1.84 1.48-3.48 3.1-3.77 5.63a.88.88 0 0 1-.6.79Z"
          className="cls-3"
        />
        <path
          d="M8.16 83.67a14.15 14.15 0 0 1-3.14-8.33c0-.32.14-.73-.32-.92l.1-.86s.07 0 .07-.07a.65.65 0 0 0 0-.19l.06-.43c.73-.23.82.24 1 .79a26.63 26.63 0 0 0 1.88 5.31c2.22 4.34 5.62 5 9.89 3.08 3.23-1.47 6-3.65 9-5.58a22.54 22.54 0 0 1 10.18-4 12.37 12.37 0 0 1 4.88.38c0 .43-.28.48-.63.51-.86.05-1.72.13-2.58.22a3.12 3.12 0 0 0-3.06 2c-2.22 5.07-6.2 7.11-11.77 6.36a1.19 1.19 0 0 0-.86.25c-1.16.72-2.31 1.48-3.53 2.09a11.18 11.18 0 0 1-7.76 1.13 20.08 20.08 0 0 1-3.41-1.74Z"
          className="cls-3"
        />
        <path
          d="M4.83 73.3a.65.65 0 0 1 0 .19s-.05 0-.07.07a.77.77 0 0 1-.05-.19s.09-.05.12-.07Z"
          className="cls-2"
        />
        <path
          d="M314 430.54a6.11 6.11 0 0 1-1.72 4.72c-.83 1-1.95.95-3 1.14a31.58 31.58 0 0 0-14.13 6.09 13.33 13.33 0 0 1-8.42 3.15c-5.37 0-7-3.2-6.48-7.92a22.45 22.45 0 0 1 1.82-6.13 59.53 59.53 0 0 1 3.47-6.32c2-3.43 4.14-6.84 3.87-11.09a11.66 11.66 0 0 0-.72-3.32 87.15 87.15 0 0 0-3.35-8.53c-1.5-3.06-3.13-6.06-4.62-9.11-1.05-2.16-2.45-4.18-3-6.57s-.75-4.67.59-6.94c1.1-1.87 4.55-3.09 6.8-.95a9.9 9.9 0 0 1 2.56 4.25 50.87 50.87 0 0 0 3.59 8.18c1.24 2.33 2.43 4.69 3.79 6.95 2.07 3.45 4.3 6.8 6.32 10.28a94.93 94.93 0 0 0 5.54 7.91c1.31 1.8 2.83 3.46 4 5.37a19.2 19.2 0 0 1 2.66 5.9 14.61 14.61 0 0 1 .43 2.94Zm-3.53-.79c.05-2.25-1.12-3.8-2.18-5.37-2-3-4.44-5.68-6.45-8.69a112.69 112.69 0 0 1-7.09-12.45c-1.41-2.85-2.48-5.86-3.8-8.76-1.64-3.61-3.34-7.18-5.1-10.74-.18-.35-.35-1.07-.93-.83s-.16.85-.21 1.29a.25.25 0 0 0 0 .12c.95 2.9 1.86 5.82 2.87 8.7.9 2.57 1.87 5.12 2.88 7.65.92 2.31 2 4.55 2.88 6.87 1 2.58 2.3 5 3.48 7.47a37.19 37.19 0 0 0 6 8.51 6.34 6.34 0 0 1 1.88 3.17 16 16 0 0 1 .21 4.8 2.16 2.16 0 0 0 1.11 2.18 2.3 2.3 0 0 0 2.46-.16 4.68 4.68 0 0 0 2.01-3.76Z"
          style={{
            fill: "#f4c5ab",
          }}
          transform="translate(-266.42 -372.59)"
        />
        <path
          d="M274.58 456.26a20.08 20.08 0 0 0 3.34 1.68 11.18 11.18 0 0 0 7.76-1.13c1.22-.61 2.37-1.37 3.53-2.09a1.19 1.19 0 0 1 .86-.25c5.57.75 9.55-1.29 11.77-6.36a3.12 3.12 0 0 1 3.06-2c.86-.09 1.72-.17 2.58-.22.35 0 .63-.08.63-.51.81.28 1.05.64.24 1.24-4 3-7.18 6.85-11.08 9.92a18 18 0 0 1-6.08 2.95 24.81 24.81 0 0 1-8.22 1 11.69 11.69 0 0 1-7.79-3.07 1.59 1.59 0 0 1-.6-1.16Z"
          style={{
            fill: "#b76546",
          }}
          transform="translate(-266.42 -372.59)"
        />
        <path
          d="M310.49 429.75a4.68 4.68 0 0 1-2 3.76 2.3 2.3 0 0 1-2.46.16 2.16 2.16 0 0 1-1.11-2.18 16 16 0 0 0-.21-4.8 6.34 6.34 0 0 0-1.88-3.17 37.19 37.19 0 0 1-6-8.51c-1.18-2.48-2.51-4.89-3.48-7.47-.88-2.32-2-4.56-2.88-6.87-1-2.53-2-5.08-2.88-7.65-1-2.88-1.92-5.8-2.87-8.7a.25.25 0 0 1 0-.12c0-.44-.27-1.09.21-1.29s.75.48.93.83c1.76 3.56 3.46 7.13 5.1 10.74 1.32 2.9 2.39 5.91 3.8 8.76a112.69 112.69 0 0 0 7.09 12.45c2 3 4.44 5.69 6.45 8.69 1.07 1.62 2.24 3.12 2.19 5.37Z"
          style={{
            fill: "#fbe1d2",
          }}
          transform="translate(-266.42 -372.59)"
        />
      </g>
      <path
        id="Nez_contours"
        d="M281.16 375a17.63 17.63 0 0 1 4.28.55 5.18 5.18 0 0 1 4 4.58c.3 1.16.53 2.35.9 3.49a59.16 59.16 0 0 0 6.47 13.07 131.5 131.5 0 0 0 21.45 26.39 33.94 33.94 0 0 1 7.05 9.25c1.52 3.07 2.12 6.24.85 9.57a10.58 10.58 0 0 1-3.92 4.46 86.18 86.18 0 0 1-9.93 5.89 42 42 0 0 0-5.94 3.82c-1.84 1.47-3.48 3.1-3.77 5.62a.89.89 0 0 1-.59.81c-.12.93-.92 1.22-1.59 1.57a18.53 18.53 0 0 1-8 1.8h-1.12a26 26 0 0 1-9.95-1.93c-5.45-2.23-8.42-6.58-9.88-12.1a10.89 10.89 0 0 1-.37-4.88c0-.3.06-.58.1-.87 0 0 .07 0 .07-.07a.65.65 0 0 0 0-.19l.06-.43c.39-2 .81-4 1.15-6 .22-1.23.32-2.49.47-3.74-.08-2 .2-4.05.05-6.11-.26-3.37-.86-6.69-1.31-10-.66-4.85-1.37-9.7-1.9-14.58a120.36 120.36 0 0 1-.85-17.29 24.5 24.5 0 0 1 1.22-6.94c.77-2.23 2.63-3.32 4.62-4.27a14.65 14.65 0 0 1 6.4-1.46m0-2.45a17.14 17.14 0 0 0-7.46 1.7c-1.64.78-4.69 2.24-5.87 5.69a26.29 26.29 0 0 0-1.35 7.64 121.61 121.61 0 0 0 .86 17.64c.41 3.84 1 7.7 1.46 11.44.15 1.07.3 2.14.44 3.21s.29 2 .44 3c.35 2.37.68 4.61.86 6.9a26 26 0 0 1 0 3.05c0 .89-.07 1.82 0 2.77 0 .33-.08.66-.11 1-.1.85-.18 1.66-.32 2.44-.23 1.37-.51 2.77-.78 4.13l-.36 1.82-.08.77v.22l-.07.62a13.24 13.24 0 0 0 .43 5.88c1.78 6.77 5.58 11.4 11.31 13.75a28.49 28.49 0 0 0 10.88 2.1h1.21a20.82 20.82 0 0 0 9-2.07l.2-.1a4.78 4.78 0 0 0 2.35-2.14 3.46 3.46 0 0 0 .94-2c.17-1.55 1.18-2.64 2.86-4a40.75 40.75 0 0 1 5.61-3.6c.78-.44 1.57-.86 2.36-1.29a61.37 61.37 0 0 0 7.84-4.78 12.67 12.67 0 0 0 4.73-5.53c1.41-3.66 1.1-7.43-.94-11.54a35.75 35.75 0 0 0-7.55-9.93 129.83 129.83 0 0 1-21.06-25.9 57.89 57.89 0 0 1-6.22-12.53c-.22-.7-.4-1.44-.58-2.22-.08-.35-.17-.7-.25-1a7.62 7.62 0 0 0-5.79-6.44 19.93 19.93 0 0 0-4.88-.63Z"
        data-name="Nez contours"
        style={{
          fill: "#fff",
        }}
        transform="translate(-266.42 -372.59)"
      />
    </g>
  </svg>
)
export default SymboleOdorat
