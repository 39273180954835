import "./Header.css";
import SidebarMenu from "./SidebarMenu";

function Header() {
  return (
    <div className="header">
      <SidebarMenu />
    </div>
  );
}

export default Header;
