import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./HomePage.css";
import { useNavigate } from "react-router-dom";

function renderStars(rating) {
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    stars.push(
      <span key={i} className={i <= rating ? "HOMEPAGEstar filled" : "HOMEPAGEstar"}>
        ★
      </span>
    );
  }
  return <div className="HOMEPAGEstars">{stars}</div>;
}

function HomePage() {
  const navigate = useNavigate();

  const [tastingNotes, setTastingNotes] = useState([]);
  const [fetchError, setFetchError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortRating, setSortRating] = useState("none");
  const [sortDate, setSortDate] = useState("none");

  useEffect(() => {
    fetch("https://api.beerbuddy.fr/tastingnotes")
      .then((response) => response.json())
      .then((data) => {
        setTastingNotes(data);
      })
      .catch((error) => {
        setFetchError(error.message);
      });
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleRatingSortChange = (e) => {
    setSortRating(e.target.value);
  };

  const handleDateSortChange = (e) => {
    setSortDate(e.target.value);
  };

  const filteredNotes = tastingNotes.filter((note) => note.beerName.toLowerCase().includes(searchTerm.toLowerCase()) || note.breweryName.toLowerCase().includes(searchTerm.toLowerCase()));

  let sortedNotes = [...filteredNotes];
  if (sortRating === "ascending") sortedNotes.sort((a, b) => a.rating - b.rating);
  if (sortRating === "descending") sortedNotes.sort((a, b) => b.rating - a.rating);
  if (sortDate === "ascending") sortedNotes.sort((a, b) => new Date(a.tastingDate) - new Date(b.tastingDate));
  if (sortDate === "descending") sortedNotes.sort((a, b) => new Date(b.tastingDate) - new Date(a.tastingDate));

  return (
    <main className="HomePage">
      <div className="HeadHomePage">
        <img className="LogoHome" src="./logoBeerBuddy.png" alt="Logo" />
        <p className="TextHomePage">Bienvenue, je suis ton compagnon de dégustation de bière !</p>
      </div>
      <div className="BeerListAndResearch">
        {fetchError && <p>Error: {fetchError}</p>}
        <div className="search-container">
          <input className="search-bar" type="text" placeholder="Recherche" onChange={handleSearchChange} />
          <select className="Tri" onChange={handleRatingSortChange}>
            <option value="none">Tri par note</option>
            <option value="ascending">Croissant</option>
            <option value="descending">Décroissant</option>
          </select>
          <select className="Tri" onChange={handleDateSortChange}>
            <option value="none">Tri par date</option>
            <option value="ascending">Croissant</option>
            <option value="descending">Décroissant</option>
          </select>
        </div>
        <div>
          <button className="buttonMap" onClick={() => navigate("/brewery")}>
            Carte des brasseries de France
          </button>
          <p className="TextHomePage">Les dégustations publiques :</p>
          <ul>
            {sortedNotes.map((note) => (
              <Link to={`/beers/${note.id}`} className="beer-link">
                <div className="beer-name">{note.beerName}</div>
                <div className="beer-brewery">{note.breweryName}</div>
                <div>{renderStars(note.rating)}</div>
              </Link>
            ))}
          </ul>
        </div>
      </div>
    </main>
  );
}

export default HomePage;
