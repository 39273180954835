import "./GenAromaWheel.css";
import React, { useState } from "react";

function GenAromaWheel({ onSelect, props }) {
  const [selectedColors, setSelectedColors] = useState([]);

  function isSelected(currentLevel) {
    const aromaRegex = /(\D+)(\d+)/;
    const matches = currentLevel.match(aromaRegex);

    if (!matches) return false;

    const aromaType = matches[1].toUpperCase();
    const currentLevelNumber = parseInt(matches[2]);

    return selectedColors.some((selectedColor) => {
      const selectedMatches = selectedColor.match(aromaRegex);
      if (!selectedMatches) return false;

      const selectedAromaType = selectedMatches[1].toUpperCase();
      const selectedLevelNumber = parseInt(selectedMatches[2]);

      return (
        aromaType === selectedAromaType &&
        currentLevelNumber <= selectedLevelNumber
      );
    });
  }
  const handleSegmentClick = (event) => {
    const clickedElementId = event.target.id;

    // Extraire l'arôme et le niveau de l'élément cliqué
    const aromaRegex = /(\D+)(\d+)/;
    const matches = clickedElementId.match(aromaRegex);
    if (!matches) return;

    const aromaType = matches[1].toUpperCase();
    const currentLevelNumber = parseInt(matches[2]);

    // Mise à jour de selectedColors
    let newSelectedColors = selectedColors.filter((selectedColor) => {
      const selectedMatches = selectedColor.match(aromaRegex);
      if (!selectedMatches) return false;

      const selectedAromaType = selectedMatches[1].toUpperCase();
      const selectedLevelNumber = parseInt(selectedMatches[2]);

      // Conserver uniquement les arômes différents ou les niveaux inférieurs du même arôme
      return (
        selectedAromaType !== aromaType ||
        selectedLevelNumber < currentLevelNumber
      );
    });

    // Vérifier si le niveau actuel est déjà sélectionné
    if (!newSelectedColors.includes(clickedElementId)) {
      newSelectedColors.push(clickedElementId);
    }

    setSelectedColors(newSelectedColors);

    if (onSelect) {
      onSelect(newSelectedColors);
    }

    // Afficher les arômes sélectionnés dans la console
    console.log("Arômes sélectionnés:", newSelectedColors);
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="CIBLE_GENERAL"
      data-name="CIBLE GENERAL"
      viewBox="0 0 151.93 147.02"
      {...props}
      onClick={handleSegmentClick}
    >
      <defs>
        <style>
          {
            ".cls-4{fill:none;stroke:#79481d;stroke-miterlimit:10}.cls-3{fill:#1d1d1b}"
          }
        </style>
      </defs>
      <g id="CONTOURS_EXTERIEURS" data-name="CONTOURS EXTERIEURS">
        <path
          id="CONTOUR_BLANC"
          d="M295.24 351.53a61.33 61.33 0 0 1 35.86 111.09 61.33 61.33 0 1 1-35.85-111.09m0-3a64.28 64.28 0 0 0-64.33 64.33 64.57 64.57 0 0 0 26.72 52.21 64.33 64.33 0 0 0 74.42.55l.8-.56a64.32 64.32 0 0 0 .73-103.86 63.87 63.87 0 0 0-38.35-12.67Z"
          data-name="CONTOUR BLANC"
          style={{
            fill: "#fff",
          }}
          transform="translate(-218.72 -342.85)"
        />
        <path
          id="CONTOUR_MARRON"
          d="M356.58 412.86a61.21 61.21 0 0 1-25.48 49.76 61.39 61.39 0 1 1 25.49-49.76Z"
          data-name="CONTOUR MARRON"
          style={{
            strokeWidth: 2,
            fill: "none",
            stroke: "#79481d",
            strokeMiterlimit: 10,
          }}
          transform="translate(-218.72 -342.85)"
        />
      </g>
      <g id="POLICES" className="TEXTWHEEL">
        <g id="SUCRE">
          <path
            d="M23.1 25.63a4.87 4.87 0 0 0 2-1.3c.74-.83.77-1.64.19-2.16s-1.17-.41-2.31.2c-1.46.8-2.76 1-3.78.06s-1.16-2.79.22-4.33a5 5 0 0 1 1.88-1.38l.77 1.25a3.91 3.91 0 0 0-1.61 1.11c-.74.82-.62 1.56-.2 1.93s1.16.31 2.38-.31c1.57-.8 2.72-.84 3.74.07s1.35 2.78-.29 4.6a5.5 5.5 0 0 1-2.17 1.51ZM32.28 16.23c.54.61 1 1.1 1.42 1.5l-1.13 1-.76-.72a2.5 2.5 0 0 1-.84 2.36c-.94.84-2.29 1-3.79-.64l-2.69-3 1.29-1.16 2.5 2.79c.76.85 1.52 1.15 2.21.54a1.45 1.45 0 0 0 .37-1.58 1.71 1.71 0 0 0-.29-.47l-2.77-3.09 1.29-1.16ZM38.77 13.76a4.9 4.9 0 0 1-1.36 1.43 3.31 3.31 0 0 1-4.94-.74 3.54 3.54 0 0 1 .93-5.22 4.48 4.48 0 0 1 1.54-.74l.52 1.24a2.85 2.85 0 0 0-1.22.56 2.23 2.23 0 0 0 2.64 3.59 3.33 3.33 0 0 0 .94-1ZM37.22 9.49c-.49-.79-.86-1.35-1.21-1.86l1.28-.79.74 1.08a2 2 0 0 1 .83-2.23 1.39 1.39 0 0 1 .37-.18l.86 1.39a2 2 0 0 0-.46.23A1.54 1.54 0 0 0 39 9.07a2.26 2.26 0 0 0 .24.46l1.88 3-1.49.91ZM43.28 7.89a1.88 1.88 0 0 0 2.71.63 5.36 5.36 0 0 0 1.54-1.15l.78.95a6.31 6.31 0 0 1-1.95 1.48 3.26 3.26 0 0 1-4.74-1.5 3.41 3.41 0 0 1 1.32-4.82c2-1 3.48.29 4.16 1.64a3.37 3.37 0 0 1 .28.68ZM43.53 0l-.59 2.65-1.07.55.14-2.42Zm1.89 5.43a1.48 1.48 0 0 0-2-.84 1.71 1.71 0 0 0-.66 2.22Z"
            className="cls-3"
          />
        </g>
        <g id="SALE">
          <path
            d="M108.61 8.45a4.92 4.92 0 0 0 1.67 1.7c1 .55 1.76.41 2.14-.27s.15-1.23-.68-2.21c-1.08-1.26-1.54-2.49-.86-3.68.77-1.35 2.49-1.72 4.28-.7a5.09 5.09 0 0 1 1.75 1.54l-1.06 1a4.14 4.14 0 0 0-1.43-1.33c-1-.55-1.65-.27-1.93.22s-.06 1.2.81 2.25c1.12 1.37 1.4 2.49.72 3.67-.76 1.33-2.42 1.92-4.55.7a5.53 5.53 0 0 1-1.94-1.81ZM116.78 15.84l.35-.69a2.49 2.49 0 0 1-2.18-.43 2 2 0 0 1-.58-2.92c1-1.41 2.78-1.23 4.84.25l.07-.09c.26-.37.55-1.08-.44-1.79a3.78 3.78 0 0 0-1.81-.66l.38-1.12a5.08 5.08 0 0 1 2.39 1c1.79 1.28 1.49 2.78.61 4l-1.44 2a10.65 10.65 0 0 0-.88 1.41Zm1.77-2.87c-1-.73-2.09-1.2-2.7-.35a.91.91 0 0 0 .23 1.38 1.41 1.41 0 0 0 1.66 0 1.37 1.37 0 0 0 .26-.28ZM125.52 9.95l1.34 1.12-6.44 7.69-1.34-1.12ZM124.54 18.47a1.87 1.87 0 0 0 .27 2.76 5.35 5.35 0 0 0 1.58 1.1l-.65 1a6.16 6.16 0 0 1-2-1.37 3.26 3.26 0 0 1-.11-5 3.41 3.41 0 0 1 5-.3c1.61 1.56.84 3.39-.21 4.47a3.65 3.65 0 0 1-.55.48Zm3 1.24a1.47 1.47 0 0 0 .15-2.2 1.7 1.7 0 0 0-2.31.09Zm4.54-3.53-2.7.29-.86-.84 2.34-.64Z"
            className="cls-3"
          />
        </g>
        <g id="AMER">
          <path
            d="m138.36 111.22 1.83 2.13-1 1.51-6.4-7.69 1.19-1.86 9.69 2.49-1 1.58-2.73-.72Zm.36-3-2.39-.63c-.59-.16-1.22-.37-1.77-.55.39.41.87.89 1.26 1.34l1.59 1.88ZM140.28 104.15c-.73-.32-1.34-.57-1.89-.78l.6-1.36 1 .34a2.34 2.34 0 0 1-.2-2.41 2 2 0 0 1 1.93-1.23 2.9 2.9 0 0 1-.45-1.12 2.44 2.44 0 0 1 .2-1.45c.47-1 1.65-1.77 3.66-.87l3.7 1.65-.69 1.55-3.47-1.55c-1.05-.47-1.83-.38-2.17.37a1.31 1.31 0 0 0 .35 1.48 1.73 1.73 0 0 0 .49.32l3.77 1.69-.7 1.54-3.63-1.62c-.88-.4-1.66-.32-2 .41a1.37 1.37 0 0 0 .38 1.54 1.66 1.66 0 0 0 .49.32l3.71 1.66-.69 1.55ZM147.04 92.5a1.87 1.87 0 0 0 2.32-1.53 5.08 5.08 0 0 0 .23-1.91l1.23.09a6.17 6.17 0 0 1-.27 2.43 3.26 3.26 0 0 1-4.35 2.41 3.41 3.41 0 0 1-2.59-4.27c.64-2.15 2.61-2.32 4.05-1.9a4.11 4.11 0 0 1 .69.27Zm-.3-3.24a1.48 1.48 0 0 0-2 .9 1.71 1.71 0 0 0 1.16 2ZM147 86.15c-.9-.19-1.57-.31-2.18-.4l.31-1.47 1.29.2v-.05a2 2 0 0 1-1-2.14 1.49 1.49 0 0 1 .12-.39l1.6.33a2.2 2.2 0 0 0-.15.49 1.53 1.53 0 0 0 1 1.79 2.26 2.26 0 0 0 .5.15l3.5.72-.41 1.77Z"
            className="cls-3"
          />
        </g>
        <g id="ACIDE">
          <path
            d="m61.07 142.69-1.3 2.49-1.77-.33 4.78-8.79 2.18.41 1.26 9.94-1.83-.35-.35-2.81Zm2.94-.78-.3-2.45c-.07-.61-.11-1.28-.14-1.85-.24.52-.5 1.14-.77 1.66l-1.17 2.18ZM72.83 146.74a5 5 0 0 1-2 .19 3.31 3.31 0 0 1-3.23-3.8 3.54 3.54 0 0 1 4.12-3.32 4.38 4.38 0 0 1 1.65.45l-.42 1.28a2.9 2.9 0 0 0-1.29-.38 2.23 2.23 0 0 0-.37 4.43 3.46 3.46 0 0 0 1.38-.15ZM74.45 146.97l.11-6.88h1.74l-.11 6.88Zm2-8.78a.93.93 0 0 1-1 .92.91.91 0 0 1-.94-.95 1 1 0 0 1 2 0ZM84.13 136.6l.54 8.09c.05.7.12 1.45.18 1.92l-1.54.1-.15-1.08a2.46 2.46 0 0 1-2.15 1.39c-1.64.11-3-1.2-3.17-3.31-.16-2.3 1.18-3.76 2.83-3.87a2.2 2.2 0 0 1 2 .76l-.25-3.89Zm-1.33 6.12a2.34 2.34 0 0 0-.07-.45 1.54 1.54 0 0 0-1.58-1.12c-1.12.08-1.67 1.1-1.58 2.38s.77 2.14 1.86 2.06a1.54 1.54 0 0 0 1.42-1.33 2.15 2.15 0 0 0 0-.51ZM87.87 143.15a1.88 1.88 0 0 0 2.4 1.4 5.21 5.21 0 0 0 1.81-.65l.46 1.14a6 6 0 0 1-2.29.84 3.25 3.25 0 0 1-4.09-2.82 3.4 3.4 0 0 1 2.68-4.22c2.2-.38 3.23 1.3 3.49 2.78a3.26 3.26 0 0 1 .07.73Zm2.77-1.72a1.48 1.48 0 0 0-1.7-1.4 1.69 1.69 0 0 0-1.28 1.93Z"
            className="cls-3"
          />
        </g>
        <g id="UMAMI">
          <path
            d="m9.39 77.08-5.5.73c-1.85.25-2.6 1.13-2.45 2.3s1.12 1.88 3 1.63L9.95 81l.23 1.72-5.39.73c-2.91.39-4.41-.94-4.71-3.22s.74-3.76 3.71-4.16l5.4-.72ZM5.79 85.23c.76-.22 1.39-.43 2-.63l.41 1.43-.94.36a2.38 2.38 0 0 1 1.71 1.71 2 2 0 0 1-.69 2.18 2.76 2.76 0 0 1 1.06.57 2.39 2.39 0 0 1 .78 1.23c.31 1.09-.11 2.42-2.23 3L4 96.21l-.47-1.63 3.65-1.06c1.1-.32 1.64-.89 1.41-1.68a1.3 1.3 0 0 0-1.22-.91 1.73 1.73 0 0 0-.58.08l-4 1.14-.48-1.63 3.82-1.1c.93-.27 1.48-.83 1.26-1.6a1.4 1.4 0 0 0-1.3-.93 1.61 1.61 0 0 0-.57.08L1.61 88.1l-.47-1.63ZM6.55 102.15l.65-.42a2.53 2.53 0 0 1-1.66-1.48 2 2 0 0 1 1-2.82c1.58-.7 3 .36 4 2.69l.1-.05c.42-.18 1-.65.54-1.76a3.74 3.74 0 0 0-1.22-1.49l.89-.77a5 5 0 0 1 1.57 2 2.57 2.57 0 0 1-1.51 3.77l-2.27 1a11.44 11.44 0 0 0-1.47.77Zm3-1.58c-.49-1.13-1.2-2.1-2.16-1.67a.92.92 0 0 0-.5 1.31 1.42 1.42 0 0 0 1.42.86 1.45 1.45 0 0 0 .37-.11ZM12.28 103.24c.68-.41 1.23-.77 1.72-1.11l.78 1.27-.82.59a2.35 2.35 0 0 1 2.09 1.2 2 2 0 0 1-.08 2.29 2.89 2.89 0 0 1 1.18.27 2.43 2.43 0 0 1 1.07 1c.59 1 .53 2.37-1.35 3.52l-3.46 2.11-.89-1.45 3.25-2c1-.6 1.35-1.29.92-2a1.31 1.31 0 0 0-1.41-.55 1.92 1.92 0 0 0-.54.22l-3.46 2.22-.88-1.45 3.39-2.08c.83-.5 1.21-1.18.79-1.87a1.38 1.38 0 0 0-1.49-.56 2 2 0 0 0-.54.23l-3.47 2.12-.88-1.45ZM14.93 116.21l5.47-4.18 1 1.38-5.46 4.19Zm8.11-3.89a1 1 0 0 1-1.18-1.54.93.93 0 0 1 1.34.2.92.92 0 0 1-.16 1.34Z"
            className="cls-3"
          />
        </g>
      </g>
      <g className="BASE">
        <g id="UMAMI" data-name="UMAMI">
          <path
            id="UMAMI5"
            d="m266.83 452.38-7.44 10.24a61.43 61.43 0 0 1-22.6-68.36l12.06 3.92a48.7 48.7 0 0 0 18 54.19Z"
            className={`UMAMI5 ${isSelected("UMAMI5") ? "selectedGen" : ""}`}
            transform="translate(-218.72 -342.85)"
          />
          <path
            id="UMAMI4"
            d="m274.28 442.13-7.45 10.25a48.7 48.7 0 0 1-18-54.19l12 3.91a36 36 0 0 0 13.4 40Z"
            className={`UMAMI4 ${isSelected("UMAMI4") ? "selectedGen" : ""}`}
            transform="translate(-218.72 -342.85)"
          />
          <path
            id="UMAMI3"
            d="m281.72 431.87-7.44 10.26a36 36 0 0 1-13.4-40l12.05 3.87a23.35 23.35 0 0 0 8.79 25.85Z"
            className={`UMAMI3 ${isSelected("UMAMI3") ? "selectedGen" : ""}`}
            transform="translate(-218.72 -342.85)"
          />
          <path
            id="UMAMI2"
            d="m289.17 421.62-7.45 10.25a23.35 23.35 0 0 1-8.79-25.87l12.07 3.94a10.46 10.46 0 0 0-.41 2.92 10.62 10.62 0 0 0 4.58 8.76Z"
            className={`UMAMI2 ${isSelected("UMAMI2") ? "selectedGen" : ""}`}
            transform="translate(-218.72 -342.85)"
          />
          <path
            id="UMAMI1"
            d="m295.24 413.27-6.07 8.35a10.62 10.62 0 0 1-4.59-8.76 10.46 10.46 0 0 1 .41-2.92Z"
            className={`UMAMI1 ${isSelected("UMAMI1") ? "selectedGen" : ""}`}
            transform="translate(-218.72 -342.85)"
          />
        </g>
        <g id="SUCRE" data-name="SUCRE">
          <path
            id="SUCRE5"
            d="M295.24 351.53v12.67a48.65 48.65 0 0 0-46.4 34l-12.06-3.92a61.36 61.36 0 0 1 58.46-42.75Z"
            className={`SUCRE5 ${isSelected("SUCRE5") ? "selectedGen" : ""}`}
            transform="translate(-218.72 -342.85)"
          />
          <path
            id="SUCRE4"
            d="M295.24 364.2v12.66a36 36 0 0 0-34.36 25.24l-12-3.91a48.65 48.65 0 0 1 46.36-33.99Z"
            className={`SUCRE4 ${isSelected("SUCRE4") ? "selectedGen" : ""}`}
            transform="translate(-218.72 -342.85)"
          />
          <path
            id="SUCRE3"
            d="M295.24 376.86v12.67A23.35 23.35 0 0 0 272.93 406l-12.05-3.92a36 36 0 0 1 34.36-25.22Z"
            className={`SUCRE3 ${isSelected("SUCRE3") ? "selectedGen" : ""}`}
            transform="translate(-218.72 -342.85)"
          />
          <path
            id="SUCRE2"
            d="M295.24 389.53v12.67a10.64 10.64 0 0 0-10.24 7.74L272.93 406a23.35 23.35 0 0 1 22.31-16.47Z"
            className={`SUCRE2 ${isSelected("SUCRE2") ? "selectedGen" : ""}`}
            transform="translate(-218.72 -342.85)"
          />
          <path
            id="SUCRE1"
            d="M295.24 402.2v11.07L285 409.94a10.64 10.64 0 0 1 10.24-7.74Z"
            className={`SUCRE1 ${isSelected("SUCRE1") ? "selectedGen" : ""}`}
            transform="translate(-218.72 -342.85)"
          />
        </g>
        <g id="ACIDE" data-name="ACIDE">
          <path
            id="ACIDE5"
            d="M331.09 462.62a61.32 61.32 0 0 1-71.71 0l7.44-10.24a48.68 48.68 0 0 0 56.82 0Z"
            className={`ACIDE5 ${isSelected("ACIDE5") ? "selectedGen" : ""}`}
            transform="translate(-218.72 -342.85)"
          />
          <path
            id="ACIDE4"
            d="M323.65 452.38a48.68 48.68 0 0 1-56.82 0l7.45-10.25a36 36 0 0 0 41.92 0Z"
            className={`ACIDE4 ${isSelected("ACIDE4") ? "selectedGen" : ""}`}
            transform="translate(-218.72 -342.85)"
          />
          <path
            id="ACIDE3"
            d="M316.2 442.13a36 36 0 0 1-41.92 0l7.44-10.26a23.29 23.29 0 0 0 27 0Z"
            className={`ACIDE3 ${isSelected("ACIDE3") ? "selectedGen" : ""}`}
            transform="translate(-218.72 -342.85)"
          />
          <path
            id="ACIDE2"
            d="M308.76 431.87a23.29 23.29 0 0 1-27 0l7.45-10.25a10.6 10.6 0 0 0 12.14 0Z"
            className={`ACIDE2 ${isSelected("ACIDE2") ? "selectedGen" : ""}`}
            transform="translate(-218.72 -342.85)"
          />
          <path
            id="ACIDE1"
            d="M301.31 421.62a10.6 10.6 0 0 1-12.14 0l6.07-8.35Z"
            className={`ACIDE1 ${isSelected("ACIDE1") ? "selectedGen" : ""}`}
            transform="translate(-218.72 -342.85)"
          />
        </g>
        <g id="AMER" data-name="AMER">
          <path
            id="AMER5"
            d="M356.58 412.86a61.21 61.21 0 0 1-25.48 49.76l-7.44-10.24a48.69 48.69 0 0 0 18-54.19l12.06-3.92a61.11 61.11 0 0 1 2.86 18.59Z"
            className={`AMER5 ${isSelected("AMER5") ? "selectedGen" : ""}`}
            transform="translate(-218.72 -342.85)"
          />
          <path
            id="AMER4"
            d="M343.91 412.86a48.59 48.59 0 0 1-20.26 39.52l-7.44-10.25a36 36 0 0 0 13.39-40l12-3.92a48.41 48.41 0 0 1 2.31 14.65Z"
            className={`AMER4 ${isSelected("AMER4") ? "selectedGen" : ""}`}
            transform="translate(-218.72 -342.85)"
          />
          <path
            id="AMER3"
            d="M331.24 412.86a36 36 0 0 1-15 29.27l-7.45-10.25a23.34 23.34 0 0 0 8.8-25.85l12-3.91a35.65 35.65 0 0 1 1.65 10.74Z"
            className={`AMER3 ${isSelected("AMER3") ? "selectedGen" : ""}`}
            transform="translate(-218.72 -342.85)"
          />
          <path
            id="AMER2"
            d="M318.58 412.86a23.25 23.25 0 0 1-9.82 19l-7.45-10.25a10.63 10.63 0 0 0 4.6-8.76 10.46 10.46 0 0 0-.41-2.92l12.06-3.93a23.39 23.39 0 0 1 1.02 6.86Z"
            className={`AMER2 ${isSelected("AMER2") ? "selectedGen" : ""}`}
            transform="translate(-218.72 -342.85)"
          />
          <path
            id="AMER1"
            d="M305.91 412.86a10.63 10.63 0 0 1-4.6 8.76l-6.06-8.35 10.25-3.33a10.46 10.46 0 0 1 .41 2.92Z"
            className={`AMER1 ${isSelected("AMER1") ? "selectedGen" : ""}`}
            transform="translate(-218.72 -342.85)"
          />
        </g>
        <g id="SALE" data-name="SALE">
          <path
            id="SALE5"
            d="m353.71 394.27-12.06 3.92a48.66 48.66 0 0 0-46.41-34v-12.66a61.36 61.36 0 0 1 58.47 42.74Z"
            className={`SALE5 ${isSelected("SALE5") ? "selectedGen" : ""}`}
            transform="translate(-218.72 -342.85)"
          />
          <path
            id="SALE4"
            d="m341.65 398.19-12 3.92a36 36 0 0 0-34.36-25.25V364.2a48.66 48.66 0 0 1 46.36 33.99Z"
            className={`SALE4 ${isSelected("SALE4") ? "selectedGen" : ""}`}
            transform="translate(-218.72 -342.85)"
          />
          <path
            id="SALE3"
            d="m329.6 402.11-12 3.91a23.35 23.35 0 0 0-22.32-16.49v-12.67a36 36 0 0 1 34.32 25.25Z"
            className={`SALE3 ${isSelected("SALE3") ? "selectedGen" : ""}`}
            transform="translate(-218.72 -342.85)"
          />
          <path
            id="SALE2"
            d="m317.56 406-12.06 3.92a10.66 10.66 0 0 0-10.26-7.74v-12.65A23.35 23.35 0 0 1 317.56 406Z"
            className={`SALE2 ${isSelected("SALE2") ? "selectedGen" : ""}`}
            transform="translate(-218.72 -342.85)"
          />
          <path
            id="SALE1"
            d="m305.5 409.94-10.25 3.33V402.2a10.66 10.66 0 0 1 10.25 7.74Z"
            className={`SALE1 ${isSelected("SALE1") ? "selectedGen" : ""}`}
            transform="translate(-218.72 -342.85)"
          />
        </g>{" "}
      </g>
    </svg>
  );
}
export default GenAromaWheel;
