import React, { useState } from "react";
import Login from "../Auth/Login";
import SignUp from "../Auth/SignUp";
import { motion, AnimatePresence } from "framer-motion";
import "./RequiredAuth.css";

function RequiredAuth() {
  const [showLogin, setShowLogin] = useState(true);

  return (
    <div>
      <p className="RequiredAuthAlert">
        Vous devez être connecté pour accéder à cette page.
      </p>
      <AnimatePresence mode="wait">
        {showLogin ? (
          <motion.div
            key="login"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Login />
            <button
              className="RequiredAuth-button"
              onClick={() => setShowLogin(false)}
            >
              S'inscrire
            </button>
          </motion.div>
        ) : (
          <motion.div
            key="signup"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <SignUp />
            <button
              className="RequiredAuth-button"
              onClick={() => setShowLogin(true)}
            >
              Se connecter
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default RequiredAuth;
