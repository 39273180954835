import "./MaltAromaWheel.css";
import React, { useState } from "react";

function MaltFlavorWheel({ onSelect, props }) {
  const [selectedColors, setSelectedColors] = useState([]);

  function isSelected(currentLevel) {
    const aromaRegex = /(\D+)(\d+)/;
    const matches = currentLevel.match(aromaRegex);

    if (!matches) return false;

    const aromaType = matches[1].toUpperCase();
    const currentLevelNumber = parseInt(matches[2]);

    return selectedColors.some((selectedColor) => {
      const selectedMatches = selectedColor.match(aromaRegex);
      if (!selectedMatches) return false;

      const selectedAromaType = selectedMatches[1].toUpperCase();
      const selectedLevelNumber = parseInt(selectedMatches[2]);

      return (
        aromaType === selectedAromaType &&
        currentLevelNumber <= selectedLevelNumber
      );
    });
  }

  const handleSegmentClick = (event) => {
    const clickedElementId = event.target.id;

    // Extraire l'arôme et le niveau de l'élément cliqué
    const aromaRegex = /(\D+)(\d+)/;
    const matches = clickedElementId.match(aromaRegex);
    if (!matches) return;

    const aromaType = matches[1].toUpperCase();
    const currentLevelNumber = parseInt(matches[2]);

    // Mise à jour de selectedColors
    let newSelectedColors = selectedColors.filter((selectedColor) => {
      const selectedMatches = selectedColor.match(aromaRegex);
      if (!selectedMatches) return false;

      const selectedAromaType = selectedMatches[1].toUpperCase();
      const selectedLevelNumber = parseInt(selectedMatches[2]);

      // Conserver uniquement les arômes différents ou les niveaux inférieurs du même arôme
      return (
        selectedAromaType !== aromaType ||
        selectedLevelNumber < currentLevelNumber
      );
    });

    // Vérifier si le niveau actuel est déjà sélectionné
    if (!newSelectedColors.includes(clickedElementId)) {
      newSelectedColors.push(clickedElementId);
    }

    setSelectedColors(newSelectedColors);

    if (onSelect) {
      onSelect(newSelectedColors);
    }

    // Afficher les arômes sélectionnés dans la console
    console.log("flaveurs sélectionnés:", newSelectedColors);
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="b6b43307-67ae-4b92-ab4f-889df511ae4c"
      className="CIBLE"
      data-name="CIBLE MALT"
      viewBox="0 0 154.07 149.32"
      {...props}
      onClick={handleSegmentClick}
    >
      <defs>
        <style>
          {
            ".a18874ef-fea2-4b7d-a472-f60ed60ee935{fill:none;stroke:#79481d;stroke-miterlimit:10}.a5d3611b-e6a5-4f94-96a1-0424f1d04351{fill:#1d1d1b}"
          }
        </style>
      </defs>

      <g>
        <g
          id="edc0a748-3ca4-4579-96b5-b33684850a79"
          className="BASE"
          data-name="CEREALES"
        >
          <path
            id="CEREALES5"
            d="m268.33 463.37-7.44 10.24a61.43 61.43 0 0 1-22.6-68.36l12.06 3.92a48.7 48.7 0 0 0 18 54.19Z"
            className={`CEREALES5 ${
              isSelected("CEREALES5") ? "selectedMalt" : ""
            }`}
            data-name="CEREALES5"
            transform="translate(-219.77 -351.67)"
            onClick={() => console.log("SVG clicked ouai ouai")}
          />
          <path
            id="CEREALES4"
            d="m275.78 453.12-7.45 10.25a48.7 48.7 0 0 1-18-54.19l12 3.91a36 36 0 0 0 13.4 40Z"
            className={`CEREALES4 ${
              isSelected("CEREALES4") ? "selectedMalt" : ""
            }`}
            data-name="CEREALES4"
            transform="translate(-219.77 -351.67)"
          />
          <path
            id="CEREALES3"
            d="m283.22 442.86-7.44 10.26a36 36 0 0 1-13.4-40l12.05 3.88a23.35 23.35 0 0 0 8.79 25.85Z"
            className={`CEREALES3 ${
              isSelected("CEREALES3") ? "selectedMalt" : ""
            }`}
            data-name="CEREALES3"
            transform="translate(-219.77 -351.67)"
          />
          <path
            id="CEREALES2"
            d="m290.67 432.61-7.45 10.25a23.35 23.35 0 0 1-8.79-25.86l12.06 3.92a10.46 10.46 0 0 0-.41 2.92 10.62 10.62 0 0 0 4.59 8.77Z"
            className={`CEREALES2 ${
              isSelected("CEREALES2") ? "selectedMalt" : ""
            }`}
            data-name="CEREALES2"
            transform="translate(-219.77 -351.67)"
          />
          <path
            id="CEREALES1"
            d="m296.74 424.26-6.07 8.35a10.62 10.62 0 0 1-4.59-8.76 10.46 10.46 0 0 1 .41-2.92Z"
            className={`CEREALES1 ${
              isSelected("CEREALES1") ? "selectedMalt" : ""
            }`}
            data-name="CEREALES1"
            transform="translate(-219.77 -351.67)"
          />
        </g>
        <g
          id="b0b3ba78-be03-4a82-8708-afcc836e09b5"
          className="BASE"
          data-name="CAFE"
        >
          <path
            id="CAFE5"
            d="M296.74 362.52v12.67a48.65 48.65 0 0 0-46.4 34l-12.06-3.92a61.36 61.36 0 0 1 58.46-42.75Z"
            className={`CAFE5 ${isSelected("CAFE5") ? "selectedMalt" : ""}`}
            data-name="CAFE5"
            transform="translate(-219.77 -351.67)"
          />
          <path
            id="CAFE4"
            d="M296.74 375.19v12.66a36 36 0 0 0-34.36 25.24l-12-3.91a48.65 48.65 0 0 1 46.36-33.99Z"
            className={`CAFE4 ${isSelected("CAFE4") ? "selectedMalt" : ""}`}
            data-name="CAFE4"
            transform="translate(-219.77 -351.67)"
          />
          <path
            id="CAFE3"
            d="M296.74 387.85v12.67A23.35 23.35 0 0 0 274.43 417l-12.05-3.92a36 36 0 0 1 34.36-25.23Z"
            className={`CAFE3 ${isSelected("CAFE3") ? "selectedMalt" : ""}`}
            data-name="CAFE3"
            transform="translate(-219.77 -351.67)"
          />
          <path
            id="CAFE2"
            d="M296.74 400.52v12.67a10.64 10.64 0 0 0-10.25 7.74L274.43 417a23.35 23.35 0 0 1 22.31-16.48Z"
            className={`CAFE2 ${isSelected("CAFE2") ? "selectedMalt" : ""}`}
            data-name="CAFE2"
            transform="translate(-219.77 -351.67)"
          />
          <path
            id="CAFE1"
            d="M296.74 413.19v11.07l-10.25-3.33a10.64 10.64 0 0 1 10.25-7.74Z"
            className={`CAFE1 ${isSelected("CAFE1") ? "selectedMalt" : ""}`}
            data-name="CAFE1"
            transform="translate(-219.77 -351.67)"
          />
        </g>
        <g
          id="f4822bfa-514e-4501-9683-66b6666ac91e"
          className="BASE"
          data-name="NOISETTE"
        >
          <path
            id="NOISETTE5"
            d="M332.59 473.61a61.32 61.32 0 0 1-71.71 0l7.44-10.24a48.68 48.68 0 0 0 56.82 0Z"
            className={`NOISETTE5 ${
              isSelected("NOISETTE5") ? "selectedMalt" : ""
            }`}
            data-name="NOISETTE5"
            transform="translate(-219.77 -351.67)"
          />
          <path
            id="NOISETTE4"
            d="M325.15 463.37a48.68 48.68 0 0 1-56.82 0l7.45-10.25a36 36 0 0 0 41.92 0Z"
            className={`NOISETTE4 ${
              isSelected("NOISETTE4") ? "selectedMalt" : ""
            }`}
            data-name="NOISETTE4"
            transform="translate(-219.77 -351.67)"
          />
          <path
            id="NOISETTE3"
            d="M317.7 453.12a36 36 0 0 1-41.92 0l7.44-10.26a23.29 23.29 0 0 0 27 0Z"
            className={`NOISETTE3 ${
              isSelected("NOISETTE3") ? "selectedMalt" : ""
            }`}
            data-name="NOISETTE3"
            transform="translate(-219.77 -351.67)"
          />
          <path
            id="NOISETTE2"
            d="M310.26 442.86a23.29 23.29 0 0 1-27 0l7.45-10.25a10.6 10.6 0 0 0 12.14 0Z"
            className={`NOISETTE2 ${
              isSelected("NOISETTE2") ? "selectedMalt" : ""
            }`}
            data-name="NOISETTE2"
            transform="translate(-219.77 -351.67)"
          />
          <path
            id="NOISETTE1"
            d="M302.81 432.61a10.6 10.6 0 0 1-12.14 0l6.07-8.35Z"
            className={`NOISETTE1 ${
              isSelected("NOISETTE1") ? "selectedMalt" : ""
            }`}
            data-name="NOISETTE1"
            transform="translate(-219.77 -351.67)"
          />
        </g>
        <g
          id="e807b46f-3d80-4ed9-b44c-86695a7eb841"
          className="BASE"
          data-name="CHOCOLAT"
        >
          <path
            id="CHOCOLAT5"
            d="M358.08 423.85a61.21 61.21 0 0 1-25.48 49.76l-7.44-10.24a48.69 48.69 0 0 0 18-54.19l12.06-3.92a61.11 61.11 0 0 1 2.86 18.59Z"
            className={`CHOCOLAT5 ${
              isSelected("CHOCOLAT5") ? "selectedMalt" : ""
            }`}
            data-name="CHOCOLAT5"
            transform="translate(-219.77 -351.67)"
          />
          <path
            id="CHOCOLAT4"
            d="M345.41 423.85a48.59 48.59 0 0 1-20.26 39.52l-7.44-10.25a36 36 0 0 0 13.39-40l12-3.92a48.41 48.41 0 0 1 2.31 14.65Z"
            className={`CHOCOLAT4 ${
              isSelected("CHOCOLAT4") ? "selectedMalt" : ""
            }`}
            data-name="CHOCOLAT4"
            transform="translate(-219.77 -351.67)"
          />
          <path
            id="CHOCOLAT3"
            d="M332.74 423.85a36 36 0 0 1-15 29.27l-7.45-10.25a23.34 23.34 0 0 0 8.8-25.85l12-3.91a35.65 35.65 0 0 1 1.65 10.74Z"
            className={`CHOCOLAT3 ${
              isSelected("CHOCOLAT3") ? "selectedMalt" : ""
            }`}
            data-name="CHOCOLAT3"
            transform="translate(-219.77 -351.67)"
          />
          <path
            id="CHOCOLAT2"
            d="M320.08 423.85a23.25 23.25 0 0 1-9.82 19l-7.45-10.25a10.63 10.63 0 0 0 4.6-8.76 10.46 10.46 0 0 0-.41-2.92l12.06-3.92a23.39 23.39 0 0 1 1.02 6.85Z"
            className={`CHOCOLAT2 ${
              isSelected("CHOCOLAT2") ? "selectedMalt" : ""
            }`}
            data-name="CHOCOLAT2"
            transform="translate(-219.77 -351.67)"
          />
          <path
            id="CHOCOLAT1"
            d="M307.41 423.85a10.63 10.63 0 0 1-4.6 8.76l-6.06-8.35 10.25-3.33a10.46 10.46 0 0 1 .41 2.92Z"
            className={`CHOCOLAT1 ${
              isSelected("CHOCOLAT1") ? "selectedMalt" : ""
            }`}
            data-name="CHOCOLAT1"
            transform="translate(-219.77 -351.67)"
          />
        </g>
        <g
          id="b38379ff-484f-4abb-bbae-afb94306cc86"
          className="BASE"
          data-name="CARAMEL"
        >
          <path
            id="CARAMEL5"
            d="m355.21 405.26-12.06 3.92a48.66 48.66 0 0 0-46.41-34v-12.66a61.36 61.36 0 0 1 58.47 42.74Z"
            className={`CARAMEL5 ${
              isSelected("CARAMEL5") ? "selectedMalt" : ""
            }`}
            data-name="CARAMEL5"
            transform="translate(-219.77 -351.67)"
          />
          <path
            id="CARAMEL4"
            d="m343.15 409.18-12 3.92a36 36 0 0 0-34.36-25.25v-12.66a48.66 48.66 0 0 1 46.36 33.99Z"
            className={`CARAMEL4 ${
              isSelected("CARAMEL4") ? "selectedMalt" : ""
            }`}
            data-name="CARAMEL4"
            transform="translate(-219.77 -351.67)"
          />
          <path
            id="CARAMEL3"
            d="m331.1 413.1-12 3.91a23.35 23.35 0 0 0-22.32-16.49v-12.67a36 36 0 0 1 34.32 25.25Z"
            className={`CARAMEL3 ${
              isSelected("CARAMEL3") ? "selectedMalt" : ""
            }`}
            data-name="CARAMEL3"
            transform="translate(-219.77 -351.67)"
          />
          <path
            id="CARAMEL2"
            d="M319.06 417 307 420.93a10.66 10.66 0 0 0-10.26-7.74v-12.67A23.35 23.35 0 0 1 319.06 417Z"
            className={`CARAMEL2 ${
              isSelected("CARAMEL2") ? "selectedMalt" : ""
            }`}
            data-name="CARAMEL2"
            transform="translate(-219.77 -351.67)"
          />
          <path
            id="CARAMEL1"
            d="m307 420.93-10.25 3.33v-11.07a10.66 10.66 0 0 1 10.25 7.74Z"
            className={`CARAMEL1 ${
              isSelected("CARAMEL1") ? "selectedMalt" : ""
            }`}
            data-name="CARAMEL1"
            transform="translate(-219.77 -351.67)"
          />
        </g>
      </g>
      <g
        id="f5864b81-4732-4ea2-91cd-ccfe9e272362"
        className="CONTOURS EXTERIEURS"
        data-name="CONTOURS EXTERIEURS"
      >
        <path
          id="a53cc200-2ec0-4642-ab03-67c0b986ad32"
          d="M296.74 362.52a61.33 61.33 0 0 1 35.86 111.09 61.33 61.33 0 1 1-35.85-111.09m0-3a64.36 64.36 0 0 0-61.32 44.83 64.34 64.34 0 0 0 61.32 83.84 63.78 63.78 0 0 0 36.81-11.58l.79-.57a64.3 64.3 0 0 0 .74-103.85 63.81 63.81 0 0 0-38.35-12.67Z"
          data-name="CONTOUR BLANC"
          style={{
            fill: "#fff",
          }}
          transform="translate(-219.77 -351.67)"
        />
        <path
          id="bf45b17b-181f-47d9-849a-03a137bcf562"
          d="M358.08 423.85a61.21 61.21 0 0 1-25.48 49.76 61.39 61.39 0 1 1 25.49-49.76Z"
          data-name="CONTOUR MARRON"
          style={{
            strokeWidth: 2,
            fill: "none",
            stroke: "#79481d",
            strokeMiterlimit: 10,
          }}
          transform="translate(-219.77 -351.67)"
        />
      </g>
      <g
        id="b7a442a9-b014-4785-8528-6a8e3fc09c49"
        className="POLICES"
        data-name="POLICES"
      >
        <path
          d="M64.87 72.63c-.09-2.09-.2-4.61-.19-6.81h-.07c-.53 2-1.21 4.17-1.9 6.21l-2.38 7h-2.27l-2.15-6.91c-.63-2.08-1.21-4.27-1.63-6.31h-.05c-.07 2.12-.16 4.7-.29 6.9l-.36 6.51h-2.75l1.08-16.18h3.88l2.11 6.5c.58 1.91 1.1 3.83 1.48 5.63h.07c.44-1.77 1-3.74 1.62-5.65l2.25-6.48h3.83l.93 16.18h-2.85ZM74.16 74.65l-1.38 4.58h-3.06l5.2-16.18h3.76l5.26 16.18h-3.18l-1.46-4.58Zm4.65-2.23-1.28-4c-.31-1-.57-2.1-.82-3h-.05c-.24.95-.48 2.07-.77 3l-1.25 4ZM86.23 63.05h3v13.7h6.65v2.48h-9.65ZM98.79 65.53h-4.62v-2.48h12.24v2.48h-4.66v13.7h-3Z"
          className="a5d3611b-e6a5-4f94-96a1-0424f1d04351"
        />
        <g id="a780efb3-bbd4-4162-a9df-fb16218ea706" data-name="CAFE">
          <path
            d="M33.54 20.05a6 6 0 0 1-1.52 2 4.5 4.5 0 0 1-6.83-.21 4.8 4.8 0 0 1 .32-7.15 5.36 5.36 0 0 1 2-1.26l.67 1.27a4.41 4.41 0 0 0-1.62 1 3.17 3.17 0 0 0-.06 4.84 3.11 3.11 0 0 0 4.76.26 5 5 0 0 0 1.16-1.55ZM37.46 17.49l-.56-.54a2.52 2.52 0 0 1-1.07 2 2 2 0 0 1-3-.34c-1-1.39-.33-3 1.7-4.53l-.07-.09c-.27-.36-.86-.85-1.83-.12a3.56 3.56 0 0 0-1.17 1.52l-1-.7a5.1 5.1 0 0 1 1.63-2 2.57 2.57 0 0 1 4 .62l1.48 2a10.91 10.91 0 0 0 1.08 1.26Zm-2.23-2.55c-1 .72-1.77 1.63-1.14 2.47a.91.91 0 0 0 1.38.2 1.41 1.41 0 0 0 .52-1.58 1 1 0 0 0-.19-.33ZM40.03 15.72l-3-4.71-.79.49-.69-1.09.79-.5-.16-.25a3.2 3.2 0 0 1-.55-2.43 2.58 2.58 0 0 1 1.16-1.52 3.46 3.46 0 0 1 1-.45l.61 1.19a2.14 2.14 0 0 0-.69.3c-.73.46-.58 1.22-.14 1.93l.2.31 1.27-.81.69 1.1-1.26.8 3 4.71ZM42.94 10.51a1.87 1.87 0 0 0 2.71.6 5.19 5.19 0 0 0 1.53-1.17l.79.94a6.4 6.4 0 0 1-1.93 1.51 3.26 3.26 0 0 1-4.75-1.44 3.39 3.39 0 0 1 1.25-4.83c2-1.05 3.48.24 4.18 1.57a3.83 3.83 0 0 1 .29.68Zm.15-7.88-.56 2.7-1.06.57.11-2.42Zm2 5.4a1.47 1.47 0 0 0-2.05-.81 1.69 1.69 0 0 0-.63 2.22Z"
            className="a5d3611b-e6a5-4f94-96a1-0424f1d04351"
          />
        </g>
        <g id="fb4be896-677c-4f52-8ae0-f951df43c4b3" data-name="CARAMEL">
          <path
            d="M104.04 9.96a6.13 6.13 0 0 1-2.47-.45 4.5 4.5 0 0 1-2.89-6.19 4.8 4.8 0 0 1 6.53-2.94 5.45 5.45 0 0 1 2 1.2l-.84 1.17a4.46 4.46 0 0 0-1.61-1 3.18 3.18 0 0 0-4.35 2.12 3.11 3.11 0 0 0 1.92 4.37 5 5 0 0 0 1.9.34ZM108.11 12.33l.23-.74a2.54 2.54 0 0 1-2.23-.07 2 2 0 0 1-1-2.8c.77-1.55 2.54-1.65 4.81-.52l.05-.1c.2-.41.37-1.16-.72-1.7a3.71 3.71 0 0 0-1.88-.36l.19-1.16a5 5 0 0 1 2.5.56c2 1 1.93 2.5 1.26 3.86l-1.11 2.21a10.69 10.69 0 0 0-.63 1.54Zm1.29-3.12c-1.09-.56-2.26-.85-2.72.09a.92.92 0 0 0 .44 1.33 1.45 1.45 0 0 0 1.65-.25 1.77 1.77 0 0 0 .21-.32ZM113.08 9.7c.48-.78.81-1.37 1.1-1.91l1.29.78-.63 1.15a2 2 0 0 1 2.37-.27 1.48 1.48 0 0 1 .33.24l-.85 1.4a2.07 2.07 0 0 0-.41-.3 1.53 1.53 0 0 0-2 .31 2.37 2.37 0 0 0-.31.43l-1.85 3-1.49-.91ZM117.64 18.33l.35-.69a2.54 2.54 0 0 1-2.18-.45 2 2 0 0 1-.55-2.93c1-1.41 2.79-1.21 4.83.29l.07-.09c.26-.37.56-1.08-.43-1.79a3.54 3.54 0 0 0-1.79-.67l.38-1.12a5 5 0 0 1 2.38 1c1.78 1.3 1.47 2.8.58 4l-1.45 2a10.41 10.41 0 0 0-.89 1.4Zm1.8-2.86c-1-.73-2.09-1.22-2.71-.37a.93.93 0 0 0 .22 1.39 1.44 1.44 0 0 0 1.66 0 1.79 1.79 0 0 0 .27-.28ZM123.23 16.33c.55-.58 1-1.07 1.38-1.52l1.07 1-.64.79a2.37 2.37 0 0 1 2.34.62 2 2 0 0 1 .5 2.23 2.94 2.94 0 0 1 1.21 0 2.45 2.45 0 0 1 1.29.67c.82.79 1.12 2.15-.41 3.75l-2.74 2.98-1.23-1.18 2.63-2.75c.79-.83 1-1.59.37-2.16a1.31 1.31 0 0 0-1.51-.17 1.87 1.87 0 0 0-.46.35l-2.86 3-1.22-1.17 2.75-2.88c.66-.7.85-1.46.27-2a1.37 1.37 0 0 0-1.58-.15 1.56 1.56 0 0 0-.46.35l-2.81 2.94-1.23-1.17ZM130.98 26.96a1.87 1.87 0 0 0-.07 2.78 5.28 5.28 0 0 0 1.43 1.28l-.77.95a6.33 6.33 0 0 1-1.84-1.64 3.25 3.25 0 0 1 .5-4.94 3.41 3.41 0 0 1 5 .31c1.4 1.75.42 3.47-.76 4.41a3.77 3.77 0 0 1-.61.41Zm2.84 1.6a1.48 1.48 0 0 0 .41-2.17 1.71 1.71 0 0 0-2.31-.19ZM140.47 27.73l1 1.45-8.35 5.57-1-1.44Z"
            className="a5d3611b-e6a5-4f94-96a1-0424f1d04351"
          />
        </g>
        <g id="ff5482e2-da0a-4b4e-a5f7-8d947d36a907" data-name="CHOCOLAT">
          <path
            d="M141.03 114.57a6.21 6.21 0 0 1-1.11 2.24 4.51 4.51 0 0 1-6.75 1.09 4.79 4.79 0 0 1-1-7.08 5.27 5.27 0 0 1 1.7-1.61l.89 1.12a4.59 4.59 0 0 0-1.4 1.28 3.17 3.17 0 0 0 .87 4.72 3.11 3.11 0 0 0 4.72-.65 4.81 4.81 0 0 0 .84-1.73ZM133.56 108.14l.86-1.51 3.55 2a2.42 2.42 0 0 1-.26-1.14 2.34 2.34 0 0 1 .33-1.18c.58-1 1.86-1.7 3.78-.61l3.52 2-.87 1.51-3.33-1.88c-.86-.5-1.7-.56-2.18.27a1.45 1.45 0 0 0 .18 1.66 1.55 1.55 0 0 0 .42.32l3.57 2-.86 1.51ZM145.8 98.18a3.42 3.42 0 0 1 2 4.7 3.33 3.33 0 0 1-4.62 1.75 3.4 3.4 0 0 1-1.94-4.72 3.31 3.31 0 0 1 4.56-1.73Zm-2 4.79c1.21.53 2.39.32 2.81-.65s-.21-1.93-1.49-2.48c-1-.43-2.31-.48-2.79.62s.42 2.06 1.48 2.51ZM150.97 92.85a5 5 0 0 1-.25 2 3.31 3.31 0 0 1-4.43 2.31 3.54 3.54 0 0 1-2.32-4.76 4.46 4.46 0 0 1 .8-1.5l1.16.69a2.85 2.85 0 0 0-.66 1.17 2.23 2.23 0 0 0 4.24 1.34 3.44 3.44 0 0 0 .16-1.38ZM149.54 84.22a3.42 3.42 0 0 1 2.84 4.23 3.33 3.33 0 0 1-4.19 2.62 3.41 3.41 0 0 1-2.82-4.25 3.3 3.3 0 0 1 4.17-2.6Zm-1 5.09c1.3.28 2.41-.16 2.63-1.18s-.58-1.86-1.93-2.15c-1.06-.23-2.37 0-2.62 1.16s.78 1.93 1.9 2.19ZM143.23 81.84l.23-1.72 9.94 1.31-.22 1.73ZM153.91 75.33l-.78.07a2.52 2.52 0 0 1 .81 2.08 2 2 0 0 1-2.17 2.05c-1.73-.1-2.52-1.7-2.37-4.22h-.11c-.45 0-1.21.11-1.27 1.33a3.61 3.61 0 0 0 .4 1.87l-1.15.28a5.06 5.06 0 0 1-.46-2.52c.12-2.2 1.55-2.75 3.05-2.67l2.48.14a9.89 9.89 0 0 0 1.65 0Zm-3.38 0c-.09 1.22.11 2.4 1.15 2.46a.92.92 0 0 0 1.05-.93 1.41 1.41 0 0 0-.88-1.41 1.51 1.51 0 0 0-.37-.07ZM145.08 69.96l2-.05v-1.66h1.29l.05 1.65 3-.07c.84 0 1.26-.26 1.25-.93a2.5 2.5 0 0 0-.1-.67l1.32-.07a3.86 3.86 0 0 1 .22 1.26 2 2 0 0 1-.53 1.51 2.72 2.72 0 0 1-1.92.59l-3.23.09v1h-1.29v-1h-1.55Z"
            className="a5d3611b-e6a5-4f94-96a1-0424f1d04351"
          />
        </g>
        <g id="abe97a04-7544-467d-a07c-7be3949389ca" data-name="NOISETTES">
          <path
            d="m52.42 145.25 2.58-9.17 1.92.54 1.26 4.6a28 28 0 0 1 .69 3.52c.23-1.25.53-2.44.93-3.84l.9-3.2 1.55.43-2.56 9.2-1.74-.49-1.25-4.71a33.75 33.75 0 0 1-.75-3.62h-.05c-.27 1.21-.59 2.43-1 3.93l-.93 3.28ZM69.11 145.33a3.41 3.41 0 0 1-4.05 3.07 3.33 3.33 0 0 1-2.86-4 3.41 3.41 0 0 1 4.08-3.06 3.3 3.3 0 0 1 2.83 3.99Zm-5.13-.73c-.21 1.31.29 2.4 1.33 2.56s1.81-.69 2-2.06c.17-1.07-.12-2.36-1.3-2.55s-1.86.92-2.03 2.05ZM70.39 148.89l.52-6.85 1.73.12-.51 6.86Zm2.5-8.64a.92.92 0 0 1-1.06.86.91.91 0 0 1-.88-1 1 1 0 0 1 1.94.14ZM74.23 147.55a3.84 3.84 0 0 0 1.75.52c.76 0 1.11-.31 1.11-.76s-.28-.71-1.12-1c-1.34-.47-1.89-1.21-1.88-2 0-1.22 1-2.15 2.63-2.13a3.94 3.94 0 0 1 1.82.42l-.36 1.22a3.22 3.22 0 0 0-1.45-.41c-.62 0-1 .3-1 .72s.33.65 1.19 1c1.25.45 1.82 1.1 1.82 2.11 0 1.26-1 2.15-2.81 2.13a4.37 4.37 0 0 1-2.1-.5ZM81.63 146.09a1.88 1.88 0 0 0 2.27 1.61 5.26 5.26 0 0 0 1.86-.47l.35 1.17a6.08 6.08 0 0 1-2.36.63 3.25 3.25 0 0 1-3.81-3.19 3.4 3.4 0 0 1 3.05-3.95c2.23-.18 3.11 1.59 3.23 3.09a3.45 3.45 0 0 1 0 .73Zm2.92-1.46a1.49 1.49 0 0 0-1.57-1.55 1.71 1.71 0 0 0-1.45 1.8ZM88.8 139.33l.32 1.94 1.64-.27.21 1.28-1.63.27.5 3c.14.83.44 1.21 1.09 1.1a2.54 2.54 0 0 0 .66-.19l.25 1.3a3.86 3.86 0 0 1-1.22.39 2 2 0 0 1-1.57-.31 2.78 2.78 0 0 1-.85-1.81l-.54-3.2-1 .17-.22-1.28 1-.16-.26-1.54ZM93.36 138.33l.46 1.91 1.61-.38.3 1.26-1.61.38.71 2.95c.2.82.52 1.19 1.16 1a2.52 2.52 0 0 0 .64-.23l.35 1.28a3.88 3.88 0 0 1-1.19.47 2 2 0 0 1-1.59-.2 2.76 2.76 0 0 1-1-1.75l-.76-3.15-1 .23-.31-1.26 1-.23-.37-1.51ZM98.87 142.93a1.87 1.87 0 0 0 2.58 1 5.18 5.18 0 0 0 1.7-.9l.62 1.06a6.41 6.41 0 0 1-2.14 1.24 3.26 3.26 0 0 1-4.46-2.19c-.6-1.81-.08-3.88 2-4.57s3.4.81 3.87 2.24a3.43 3.43 0 0 1 .17.71Zm2.49-2.1a1.48 1.48 0 0 0-1.89-1.14 1.7 1.7 0 0 0-1 2.09Z"
            className="a5d3611b-e6a5-4f94-96a1-0424f1d04351"
          />
        </g>
        <g id="e928db83-9892-4100-86a1-e80574ca1383" data-name="CEREALES">
          <path
            d="M18.23 106.13a6.33 6.33 0 0 1 1.72 1.84 4.49 4.49 0 0 1-1.36 6.69 4.79 4.79 0 0 1-7-1.51 5.45 5.45 0 0 1-.92-2.17l1.37-.44a4.59 4.59 0 0 0 .71 1.76 3.17 3.17 0 0 0 4.76.88 3.11 3.11 0 0 0 1.05-4.65 5 5 0 0 0-1.33-1.41ZM7.09 105.38l2.65.58.55 1.07-2.42-.13Zm7.88.23a1.87 1.87 0 0 0 .62-2.71 5.36 5.36 0 0 0-1.15-1.54l.95-.78a6.13 6.13 0 0 1 1.48 1.94 3.26 3.26 0 0 1-1.48 4.75 3.4 3.4 0 0 1-4.82-1.31c-1-2 .28-3.47 1.62-4.16a3.59 3.59 0 0 1 .67-.28Zm-2.46-2.13a1.48 1.48 0 0 0-.84 2 1.71 1.71 0 0 0 2.22.65ZM10.79 100.95c-.85.35-1.47.63-2 .89l-.57-1.39 1.18-.56a2.07 2.07 0 0 1-2.07-1.19 2.43 2.43 0 0 1-.12-.39l1.52-.62a2.62 2.62 0 0 0 .15.49 1.53 1.53 0 0 0 1.81.94 2.87 2.87 0 0 0 .5-.16l3.3-1.35.66 1.61ZM2.49 93.17l2.51 1 .37 1.15L3 94.78Zm7.74 1.56a1.88 1.88 0 0 0 1.08-2.57 5.24 5.24 0 0 0-.88-1.71l1.07-.61a6.32 6.32 0 0 1 1.14 2.17 3.26 3.26 0 0 1-2.27 4.42 3.41 3.41 0 0 1-4.54-2.1c-.67-2.13.87-3.38 2.31-3.83a3.26 3.26 0 0 1 .71-.16Zm-2.06-2.52A1.48 1.48 0 0 0 7 94.08a1.71 1.71 0 0 0 2.08 1ZM10.65 84.7l-.73.27a2.52 2.52 0 0 1 1.32 1.8 2 2 0 0 1-1.56 2.54c-1.71.35-2.88-1-3.39-3.47h-.11c-.44.09-1.14.42-.89 1.61a3.76 3.76 0 0 0 .88 1.71l-1 .56a4.87 4.87 0 0 1-1.11-2.32c-.45-2.16.79-3.06 2.27-3.36l2.42-.51a9.68 9.68 0 0 0 1.6-.44Zm-3.25.91c.23 1.21.72 2.3 1.75 2.09a.92.92 0 0 0 .77-1.17 1.41 1.41 0 0 0-1.21-1.14 1 1 0 0 0-.38 0ZM.23 83.05l-.22-1.72 9.95-1.29.23 1.73ZM6.72 77.46a1.87 1.87 0 0 0 1.68-2.22 5.17 5.17 0 0 0-.42-1.87l1.19-.33a6.26 6.26 0 0 1 .56 2.38 3.26 3.26 0 0 1-3.3 3.73 3.41 3.41 0 0 1-3.87-3.17c-.12-2.23 1.68-3.06 3.19-3.14a3.87 3.87 0 0 1 .73 0Zm-1.37-3a1.49 1.49 0 0 0-1.6 1.53 1.71 1.71 0 0 0 1.76 1.5ZM7.88 71.72a3.81 3.81 0 0 0 .56-1.74c0-.76-.27-1.12-.72-1.13s-.72.25-1.05 1.09c-.51 1.33-1.26 1.86-2.08 1.82-1.21 0-2.11-1.08-2.05-2.69a4 4 0 0 1 .48-1.81l1.21.4a3 3 0 0 0-.45 1.43c0 .62.26 1 .69 1s.66-.3 1-1.16c.48-1.23 1.14-1.79 2.16-1.75 1.25 0 2.12 1.05 2.05 2.87a4.5 4.5 0 0 1-.56 2.08Z"
            className="a5d3611b-e6a5-4f94-96a1-0424f1d04351"
          />
        </g>
      </g>
    </svg>
  );
}
export default MaltFlavorWheel;
