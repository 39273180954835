import svg1 from "./CARBO/CARBO1.svg";
import svg2 from "./CARBO/CARBO2.svg";
import svg3 from "./CARBO/CARBO3.svg";
import svg4 from "./CARBO/CARBO4.svg";
import svg5 from "./CARBO/CARBO5.svg";
import "./CarbonatationSlider.css";

function CarbonationSlider({ value, onChange }) {
  const svgPaths = [svg1, svg2, svg3, svg4, svg5];

  const handleRangeChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    onChange(newValue);
  };

  return (
    <div className="carbonation-slider">
      <img src={svgPaths[value - 1]} alt={`Carbonation Level ${value}`} />
      <div>
        <input
          className="CarboSlider"
          type="range"
          min="1"
          max="5"
          step="1"
          value={value}
          onChange={handleRangeChange}
        />
      </div>
    </div>
  );
}

export default CarbonationSlider;
