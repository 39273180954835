import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Profil.css";

function SignUp() {
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://api.beerbuddy.fr/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ nom, prenom, email, password }),
      });

      if (response.ok) {
        navigate("/login");
      } else {
        const data = await response.json();
        alert(data.error || "Erreur lors de l inscription");
      }
    } catch (error) {
      console.error(
        "Il y a eu une erreur lors de la création du compte:",
        error
      );
    }
  };

  return (
    <div className="signup-container">
      <h2 className="signup-title">Créez votre compte</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label className="signup-label">Nom :</label>
          <input
            className="signup-form-input"
            type="text"
            value={nom}
            onChange={(e) => setNom(e.target.value)}
            required
          />
        </div>
        <div>
          <label className="signup-label">Prénom :</label>
          <input
            className="signup-form-input"
            type="text"
            value={prenom}
            onChange={(e) => setPrenom(e.target.value)}
            required
          />
        </div>
        <div>
          <label className="signup-label">Email :</label>
          <input
            className="signup-form-input"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label className="signup-label">Mot de passe :</label>
          <input
            className="signup-form-input"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button className="signup-button" type="submit">
          S'inscrire
        </button>
      </form>
    </div>
  );
}

export default SignUp;
