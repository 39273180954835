import "./buttonMobile.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineHome, AiOutlineUser, AiOutlinePlus } from "react-icons/ai";
import { BiBeer } from "react-icons/bi";
import { LuPlus } from "react-icons/lu";

function HeaderMobile() {
  const navigate = useNavigate();
  const [isActiveLeft, setIsActiveLeft] = useState(true); // assuming you want this to be active by default

  const toggleLeftMenu = () => {
    setIsActiveLeft(!isActiveLeft);
  };

  return (
    <div>
      <div id="circularMenu1" className={`circular-menu circular-menu-left ${isActiveLeft ? "active" : ""}`}>
        <a className="floating-btn" onClick={toggleLeftMenu}>
          <i className="fa fa-bars">
            {" "}
            <AiOutlinePlus className="IconMenuPlus" />
          </i>
        </a>
        <menu className="items-wrapper">
          <a onClick={() => navigate("/")} className="menu-item fa fa-home">
            <i>
              <AiOutlineHome className="IconMenuMobile" />
            </i>
          </a>
          <a onClick={() => navigate("/profil")} className="menu-item fa fa-user">
            <i>
              <AiOutlineUser className="IconMenuMobile" />
            </i>
          </a>
          <a onClick={() => navigate("/MyTastingNotes")} className="menu-item fa fa-pie-chart">
            <i>
              <BiBeer className="IconMenuMobile" />
            </i>
          </a>
          <a onClick={() => navigate("/new")} className="menu-item fa fa-cog">
            <i>
              <LuPlus className="IconMenuMobile" />
            </i>
          </a>
        </menu>
      </div>
    </div>
  );
}

export default HeaderMobile;
