import svg1 from "./FOAM/FOAM1.svg";
import svg2 from "./FOAM/FOAM2.svg";
import svg3 from "./FOAM/FOAM3.svg";
import svg4 from "./FOAM/FOAM4.svg";
import svg5 from "./FOAM/FOAM5.svg";
import "./FoamSlider.css";

function FoamSlider({ value, onChange }) {
  const svgPaths = [svg1, svg2, svg3, svg4, svg5];

  const handleRangeChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    onChange(newValue);
  };

  return (
    <div className="foam-slider">
      <img src={svgPaths[value - 1]} alt={`Foam Level ${value}`} />
      <div>
        <input
          className="FoamSlider"
          type="range"
          min="1"
          max="5"
          step="1"
          value={value}
          onChange={handleRangeChange}
        />
      </div>
    </div>
  );
}

export default FoamSlider;
