import React from 'react';
import Lottie from 'lottie-react';
import Swal from 'sweetalert2';
import animationData from '../Information_custom_icon.json'; 
import './InformationIcon.css';

const InformationIconHopAroma = () => {

  const handleClick = () => {
    Swal.fire({
      title: 'Information',
      icon: 'info',
      html: `
      <strong>Agrumes</strong>: Ces arômes rappellent le piquant et la fraîcheur d'agrumes tels que le citron, l'orange ou le pamplemousse, souvent présents dans les bières houblonnées ou les bières de blé.<br>
      <strong>Épices</strong>: Ce sont des nuances qui évoquent la complexité des épices, que ce soit le poivre, la coriandre ou les clous de girofle, typiques des bières de fermentation haute ou des bières de saison.<br>
      <strong>Fleurs</strong>: Ces arômes, souvent délicats, peuvent rappeler des fleurs de champs, des roses, ou d'autres fleurs parfumées. Ils sont fréquemment retrouvés dans les bières pâles ales ou certaines lagers.<br>
      <strong>Herbes</strong>: Ces arômes évoquent la verdure, les herbes fraîchement coupées ou même le foin. Ils sont souvent associés à des bières houblonnées ou des pilsners.<br>
      <strong>Résines</strong>: Ces notes profondes et terreuses évoquent la sève d'arbres comme le pin. On les retrouve principalement dans les bières fortement houblonnées comme les IPA.
    `,
      confirmButtonText: 'Compris!'
    });
    
  };

  return (
    <div className='icon' onClick={handleClick}>
      <Lottie animationData={animationData} />
    </div>
  );
};

export default InformationIconHopAroma;
