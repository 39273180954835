import React from 'react';
import Lottie from 'lottie-react';
import Swal from 'sweetalert2';
import animationData from '../Information_custom_icon.json'; 
import './InformationIcon.css';

const InformationIcon = () => {

  const handleClick = () => {
    Swal.fire({
      title: 'Information',
      icon: 'info',
      html: `
      <strong>Café</strong>: Cet arôme rappelle la torréfaction profonde du malt, évoquant des nuances allant d'une torréfaction légère à celle d'un espresso robuste, typique des stouts et porters.<br>
      <strong>Caramel</strong>: Cet arôme est généralement le résultat de malts spécialement caramélisés. Il évoque des notes sucrées et douces, allant du caramel léger à un caramel riche et fondu, fréquent dans les bières ambrées et certaines brunes.<br>
      <strong>Chocolat</strong>: Dérivant des malts chocolatés, cet arôme offre des nuances allant du cacao pur au chocolat au lait, typique des bières brunes et stouts.<br>
      <strong>Noisette</strong>: Cet arôme évoque les nuances délicates de noix ou de noisettes grillées, souvent retrouvées dans les brown ales et autres bières maltées.<br>
      <strong>Céréales</strong>: Cet arôme est fondamental dans la bière, rappelant les grains maltés frais. Il évoque des notes allant du pain frais, des biscuits aux nuances plus douces de grains maltés, typique des lagers et pilsners.
    `,
  confirmButtonText: 'Compris!'
});
  };

  return (
    <div className='icon' onClick={handleClick}>
      <Lottie animationData={animationData} />
    </div>
  );
};

export default InformationIcon;
