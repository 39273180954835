import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BeerDetails from "./Forms/BeerDetails";
import TastingNoteForm from "./Forms/TastingNoteForm";
import Header from "./Layout/Header";
import HeaderMobile from "./Layout/buttonMobile";
import { AuthProvider } from "./Auth/AuthContext";
import Profil from "./Auth/Profil";
import Login from "./Auth/Login";
import SignUp from "./Auth/SignUp";
import HomePage from "./Pages/HomePage";
import DeconnexionComponent from "./Auth/DeconnexionComponent";
import "./App.css";
import MyTastingNotes from "./Pages/MyTastingNotes";
import Map from "./Pages/Map";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Header />
        <HeaderMobile />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/MyTastingNotes/" element={<MyTastingNotes />} />
          <Route path="/beers/:id" element={<BeerDetails />} />
          <Route path="/new" element={<TastingNoteForm />} />
          <Route path="/profil" element={<Profil />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/logout" element={<DeconnexionComponent />} />
          <Route path="/brewery" element={<Map />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
