import React from 'react';
import Lottie from 'lottie-react';
import Swal from 'sweetalert2';
import animationData from '../Information_custom_icon.json'; 
import './InformationIcon.css';

const InformationIconGen = () => {

  const handleClick = () => {
    Swal.fire({
      title: 'Information',
      icon: 'info',
      html: `
      <strong>Sucré</strong>: Cette sensation est souvent associée à la présence de sucres résiduels dans la bière, donnant une douceur agréable qui peut équilibrer l'amertume. Elle est typiquement présente dans les bières maltées et certaines ales fortes.<br>
      <strong>Salé</strong>: Moins courant dans les bières, le goût salé peut être associé à certaines bières gose ou à des bières brassées avec de l'eau à forte teneur en minéraux.<br>
      <strong>Amer</strong>: L'amertume est généralement le résultat de la présence de houblon dans la bière. Elle est prédominante dans des styles comme les IPA ou les bières pale ale, et sert à équilibrer la douceur du malt.<br>
      <strong>Acide</strong>: Les bières acides, comme les sours ou les lambics, présentent une acidité rafraîchissante, souvent due à la fermentation sauvage ou à l'ajout de fruits.<br>
      <strong>Umami</strong>: Aussi connu sous le nom de "cinquième saveur", l'umami est une saveur savoureuse et profonde, souvent associée aux aliments fermentés ou vieillis. Bien que rare, elle peut être présente dans certaines bières vieillies en fût ou brassées avec des ingrédients spéciaux.
    `,
      confirmButtonText: 'Compris!'
    });
    
  };

  return (
    <div className='icon' onClick={handleClick}>
      <Lottie animationData={animationData} />
    </div>
  );
};

export default InformationIconGen;
