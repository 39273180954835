import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "./TastingNoteForm.css";
import React, { useState } from "react";
import HopAromaWheel from "./HopAromaWheel";
import MaltAromaWheel from "./MaltAromaWheel";
import GenAromaWheel from "./GenAromaWheel";
import BeerColor from "./BeerColor";
import InformationIcon from "./InformationIcon";
import InformationIconHopAroma from "./InformationIconHopAroma";
import InformationIconHopFlaveur from "./InformationIconHopFlaveur";
import InformationIconMaltFlaveur from "./InformationIconMaltFlaveur";
import InformationIconCarbo from "./InformationIconCarbo";
import InformationIconGen from "./InformationIconGen";
import HopFlavorWheel from "./HopFlavorWheel";
import MaltFlavorWheel from "./MaltFlavorWheel";
import { useSwipeable } from "react-swipeable";
import Swal from "sweetalert2";
import StarRating from "./StarRating";
import CarbonationSlider from "./CarbonatationSlider";
import FoamSlider from "./FoamSlider";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineCheck,
} from "react-icons/ai";
import { useAuth } from "../Auth/AuthContext";
import Login from "../Auth/Login";
import { Link } from "react-router-dom";

function TastingNoteForm() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  const { user } = useAuth();

  const [MaltAroma, setMaltAroma] = useState([]);
  const [hopAroma, setHopAroma] = useState([]);
  const [GenAroma, setGenAroma] = useState([]);
  const [HopFlavor, setHopFlavor] = useState([]);
  const [MaltFlavor, setMaltFlavor] = useState([]);
  const [color, setColor] = useState("");
  const [rating, setRating] = useState(0);
  const [carbonationLevel, setCarbonationLevel] = useState(3);
  const [FoamLevel, setFoamLevel] = useState(3);

  const fields = [
    { name: "tastingDate", label: "Date de dégustation", type: "date" },
    { name: "beerName", label: "Nom de la bière", type: "text" },
    { name: "breweryName", label: "Nom de la brasserie", type: "text" },
    { name: "appearance", label: "Couleur de la bière", type: "BeerColor" },
    { name: "foamAmount", label: "Mousse", type: "typeFoam" },
    { name: "maltSmell", label: "Arômes du malt", type: "wheelMalt" },
    { name: "hopSmell", label: "Arômes du houblon", type: "wheel" },
    { name: "taste", label: "Ressenti général", type: "wheelGen" },
    { name: "maltTaste", label: "Goût du malt", type: "wheelMaltFlavor" },
    { name: "hopFlavor", label: "Goût du houblon", type: "wheelHopFlavor" },
    { name: "carbonation", label: "Bulles", type: "typeCarbonatation" },
    { name: "rating", label: "Note de dégustation", type: "ratingNote" },
    { name: "tastingNotes", label: "Notes diverses", type: "text" },
  ];

  const handlers = useSwipeable({
    onSwipedLeft: () => nextPage(),
    onSwipedRight: () => prevPage(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const prepareDataForInsertion = (data) => {
    const transformedData = { ...data };

    // Parcourir toutes les clés de l'objet
    for (const key in transformedData) {
      if (Array.isArray(transformedData[key])) {
        // Si la valeur est un tableau, convertir le tableau en chaîne de caractères
        transformedData[key] = transformedData[key].join(",");
      }
    }

    return transformedData;
  };
  if (!user) {
    return (
      <div>
        <p>Vous devez être connecté pour accéder à cette page.</p>
        <Login />
        <p>
          ou
          <Link to="/signup">s'inscrire</Link>
        </p>
      </div>
    );
  }
  console.log(user.user.id);

  const onSubmit = (data) => {
    console.log("Form submitted", data);
    const userId = user && user.user ? user.user.id : null;
    // Préparer les données avant de les envoyer
    const preparedData = {
      ...prepareDataForInsertion(data),
      id_createur: userId,
    };

    console.log("Sending the following data:", JSON.stringify(preparedData));

    fetch("https://api.beerbuddy.fr/tastingNotes", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"), // Inclure le token JWT
      },
      credentials: "include", // Ajoutez cette ligne pour inclure les cookies de session
      body: JSON.stringify(preparedData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erreur de réponse du serveur");
        }
        return response.text();
      })
      .then((text) => {
        console.log("Response text:", text);
        Swal.fire({
          icon: "success",
          title: "Succès",
          text: "Votre note de dégustation a été soumise avec succès!",
        });
        navigate("/");
      })
      .catch((error) => {
        console.error("Error:", error);
        Swal.fire({
          icon: "error",
          title: "Erreur",
          text: `L'envoi de la note de dégustation a échoué. Code d'erreur : ${error.message}`,
        });
      });
  };

  const handleMaltAromaSelect = (aromas) => {
    const filteredAromas = aromas.filter((aroma) => !aroma.includes("-"));
    const aromaMap = {};

    filteredAromas.forEach((aroma) => {
      const baseName = aroma.slice(0, -1);
      const num = parseInt(aroma.slice(-1), 10);

      // Si l'arôme n'est pas encore dans l'objet ou si sa valeur est inférieure à la valeur actuelle, mettez à jour.
      if (!aromaMap[baseName] || aromaMap[baseName] < num) {
        aromaMap[baseName] = num;
      }
    });

    // Convertissez l'objet en un tableau d'arômes uniques avec les valeurs les plus élevées.
    const uniqueAromas = Object.entries(aromaMap).map(
      ([key, value]) => key + value
    );
    const aromasString = uniqueAromas.join(", ");
    setMaltAroma(uniqueAromas);
    setValue("maltSmell", aromasString);
  };

  const handleHopAromaSelect = (aromas) => {
    const filteredAromas = aromas.filter((aroma) => !aroma.includes("-"));
    const aromaMap = {};

    filteredAromas.forEach((aroma) => {
      const baseName = aroma.slice(0, -1);
      const num = parseInt(aroma.slice(-1), 10);
      if (!aromaMap[baseName] || aromaMap[baseName] < num) {
        aromaMap[baseName] = num;
      }
    });
    const uniqueAromas = Object.entries(aromaMap).map(
      ([key, value]) => key + value
    );
    const aromaString = uniqueAromas.join(", ");
    setHopAroma(uniqueAromas);
    setValue("hopSmell", aromaString);
  };

  const handleGenAromaSelect = (aromas) => {
    const filteredAromas = aromas.filter((aroma) => !aroma.includes("-"));
    const aromaMap = {};

    filteredAromas.forEach((aroma) => {
      const baseName = aroma.slice(0, -1);
      const num = parseInt(aroma.slice(-1), 10);
      if (!aromaMap[baseName] || aromaMap[baseName] < num) {
        aromaMap[baseName] = num;
      }
    });
    const uniqueAromas = Object.entries(aromaMap).map(
      ([key, value]) => key + value
    );
    const aromaString = uniqueAromas.join(", ");
    setGenAroma(uniqueAromas);
    setValue("taste", aromaString);
  };

  const handleHopFlavorSelect = (aromas) => {
    const filteredAromas = aromas.filter((aroma) => !aroma.includes("-"));
    const aromaMap = {};

    filteredAromas.forEach((aroma) => {
      const baseName = aroma.slice(0, -1);
      const num = parseInt(aroma.slice(-1), 10);
      if (!aromaMap[baseName] || aromaMap[baseName] < num) {
        aromaMap[baseName] = num;
      }
    });
    const uniqueAromas = Object.entries(aromaMap).map(
      ([key, value]) => key + value
    );
    const aromaString = uniqueAromas.join(", ");
    setHopFlavor(uniqueAromas);
    setValue("hopFlavor", aromaString);
  };

  const handleMaltFlavorSelect = (aromas) => {
    const filteredAromas = aromas.filter((aroma) => !aroma.includes("-"));
    const aromaMap = {};

    filteredAromas.forEach((aroma) => {
      const baseName = aroma.slice(0, -1);
      const num = parseInt(aroma.slice(-1), 10);
      if (!aromaMap[baseName] || aromaMap[baseName] < num) {
        aromaMap[baseName] = num;
      }
    });
    const uniqueAromas = Object.entries(aromaMap).map(
      ([key, value]) => key + value
    );
    const aromaString = uniqueAromas.join(", ");
    setMaltFlavor(uniqueAromas);
    setValue("maltTaste", aromaString);
  };

  const handleRatingChange = (newRating) => {
    console.log("Setting rating to", newRating);
    setValue("rating", newRating);
    setRating(newRating);
  };

  const handleCarbonationLevel = (newValue) => {
    console.log("Setting carbonation to", newValue);
    setValue("carbonation", newValue);
    setCarbonationLevel(newValue);
  };
  const handleFoamLevel = (newValue) => {
    console.log("Setting foam to", newValue);
    setValue("foamAmount", newValue);
    setFoamLevel(newValue);
  };

  const handleColorSelect = (colorIndex) => {
    console.log("Selected color:", colorIndex);
    setColor(colorIndex);
    setValue("appearance", colorIndex);
  };

  const nextPage = () => {
    if (page < fields.length - 1) {
      setPage(page + 1);
    }
  };
  const goToPage = (index) => {
    if (errors[fields[index].name]) {
      setPage(index);
    }
  };

  const prevPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  return (
    <body className="TOTALITEDUTRUC">
      <form className="tasting-note-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="step-indicator">
          {fields.map((field, index) => (
            <div
              key={index}
              className={`step ${index < page ? "active" : ""} ${
                errors[field.name] ? "error" : ""
              }`}
              onClick={() => goToPage(index)}
            ></div>
          ))}
        </div>
        <div {...handlers} className="page-container">
          <div
            className="page"
            style={{ transform: `translateX(-${page * 100}%)` }}
          >
            {fields.map((field, index) => (
              <div key={index} className="field-container">
                {field.type !== "wheel" &&
                field.type !== "BeerColor" &&
                field.type !== "wheelMalt" &&
                field.type !== "typeFoam" &&
                field.type !== "wheelGen" &&
                field.type !== "wheelHopFlavor" &&
                field.type !== "wheelMaltFlavor" &&
                field.type !== "ratingNote" &&
                field.type !== "typeCarbonatation" ? (
                  <label>
                    {field.label} :
                    <input
                      type={field.type}
                      {...register(field.name, {
                        required: "Ce champ est requis",
                      })}
                    />
                    {errors[field.name] && (
                      <p className="error-message">
                        {errors[field.name].message}
                      </p>
                    )}
                  </label>
                ) : (
                  <div key={index} className="field-container">
                    {field.type !== "wheel" &&
                    field.type !== "BeerColor" &&
                    field.type !== "wheelMalt" &&
                    field.type !== "wheelGen" &&
                    field.type !== "wheelHopFlavor" &&
                    field.type !== "wheelMaltFlavor" &&
                    field.type !== "ratingNote" &&
                    field.type !== "typeCarbonatation" ? (
                      <label>
                        {field.label} :
                        <FoamSlider
                          value={FoamLevel}
                          onChange={handleFoamLevel}
                        />
                        <input
                          type="hidden"
                          {...register("Foam", {
                            required: "Ce champ est requis",
                          })}
                          value={FoamLevel}
                        />
                        {errors.Foam && (
                          <p className="error-message">{errors.Foam.message}</p>
                        )}
                      </label>
                    ) : (
                      <div key={index} className="field-container">
                        {field.type !== "BeerColor" &&
                        field.type !== "wheelMalt" &&
                        field.type !== "wheelGen" &&
                        field.type !== "wheelHopFlavor" &&
                        field.type !== "wheelMaltFlavor" &&
                        field.type !== "ratingNote" &&
                        field.type !== "typeCarbonatation" ? (
                          <div>
                            {field.label} : <InformationIconHopAroma />
                            <HopAromaWheel onSelect={handleHopAromaSelect} />
                            <input
                              type="hidden"
                              {...register("hopSmell", {
                                required: "Ce champ est requis",
                              })}
                            />
                            {errors.hopSmell && (
                              <p className="error-message">
                                {errors.hopSmell.message}
                              </p>
                            )}
                          </div>
                        ) : (
                          <div key={index} className="field-container">
                            {field.type !== "BeerColor" &&
                            field.type !== "wheelGen" &&
                            field.type !== "wheelHopFlavor" &&
                            field.type !== "wheelMaltFlavor" &&
                            field.type !== "ratingNote" &&
                            field.type !== "typeCarbonatation" ? (
                              <div>
                                {field.label} : <InformationIcon />
                                <MaltAromaWheel
                                  onSelect={handleMaltAromaSelect}
                                />
                                <input
                                  type="hidden"
                                  {...register("maltSmell", {
                                    required: "Ce champ est requis",
                                  })}
                                />
                                {errors.maltSmell && (
                                  <p className="error-message">
                                    {errors.maltSmell.message}
                                  </p>
                                )}
                              </div>
                            ) : (
                              <div key={index} className="field-container">
                                {field.type !== "BeerColor" &&
                                field.type !== "wheelHopFlavor" &&
                                field.type !== "wheelMaltFlavor" &&
                                field.type !== "ratingNote" &&
                                field.type !== "typeCarbonatation" ? (
                                  <div>
                                    {field.label} : <InformationIconGen />
                                    <GenAromaWheel
                                      onSelect={handleGenAromaSelect}
                                    />
                                    <input
                                      type="hidden"
                                      {...register("taste", {
                                        required: "Ce champ est requis",
                                      })}
                                    />
                                    {errors.taste && (
                                      <p className="error-message">
                                        {errors.taste.message}
                                      </p>
                                    )}
                                  </div>
                                ) : (
                                  <div key={index} className="field-container">
                                    {field.type !== "BeerColor" &&
                                    field.type !== "wheelMaltFlavor" &&
                                    field.type !== "ratingNote" &&
                                    field.type !== "typeCarbonatation" ? (
                                      <div>
                                        {field.label} :
                                        <InformationIconHopFlaveur />
                                        <HopFlavorWheel
                                          onSelect={handleHopFlavorSelect}
                                        />
                                        <input
                                          type="hidden"
                                          {...register("hopFlavor", {
                                            required: "Ce champ est requis",
                                          })}
                                        />
                                        {errors.hopFlavor && (
                                          <p className="error-message">
                                            {errors.hopFlavor.message}
                                          </p>
                                        )}
                                      </div>
                                    ) : (
                                      <div
                                        key={index}
                                        className="field-container"
                                      >
                                        {field.type !== "BeerColor" &&
                                        field.type !== "wheelMaltFlavor" &&
                                        field.type !== "typeCarbonatation" ? (
                                          <div>
                                            <label>{field.label} :</label>
                                            <StarRating
                                              rating={rating}
                                              onRatingChange={
                                                handleRatingChange
                                              }
                                            />
                                            <input
                                              type="hidden"
                                              {...register("rating", {
                                                required: "Ce champ est requis",
                                              })}
                                            />
                                            {errors.rating && (
                                              <p className="error-message">
                                                {errors.rating.message}
                                              </p>
                                            )}
                                          </div>
                                        ) : (
                                          <div
                                            key={index}
                                            className="field-container"
                                          >
                                            {field.type !== "BeerColor" &&
                                            field.type !==
                                              "typeCarbonatation" ? (
                                              <div>
                                                {field.label} :{" "}
                                                <InformationIconMaltFlaveur />
                                                <MaltFlavorWheel
                                                  onSelect={
                                                    handleMaltFlavorSelect
                                                  }
                                                />
                                                <input
                                                  type="hidden"
                                                  {...register("maltTaste", {
                                                    required:
                                                      "Ce champ est requis",
                                                  })}
                                                />
                                                {errors.maltTaste && (
                                                  <p className="error-message">
                                                    {errors.maltTaste.message}
                                                  </p>
                                                )}
                                              </div>
                                            ) : (
                                              <div
                                                key={index}
                                                className="field-container"
                                              >
                                                {field.type !== "BeerColor" ? (
                                                  <div>
                                                    {field.label} :
                                                    <InformationIconCarbo />
                                                    <CarbonationSlider
                                                      value={carbonationLevel}
                                                      onChange={
                                                        handleCarbonationLevel
                                                      }
                                                    />
                                                    <input
                                                      type="hidden"
                                                      {...register(
                                                        "carbonation",
                                                        {
                                                          required:
                                                            "Ce champ est requis",
                                                        }
                                                      )}
                                                      value={carbonationLevel}
                                                    />
                                                    {errors.carbonation && (
                                                      <p className="error-message">
                                                        {
                                                          errors.carbonation
                                                            .message
                                                        }
                                                      </p>
                                                    )}
                                                  </div>
                                                ) : (
                                                  <div>
                                                    <p className="titleBeerColor">
                                                      {field.label} :
                                                    </p>
                                                    <BeerColor
                                                      onSelect={
                                                        handleColorSelect
                                                      }
                                                    />
                                                    <input
                                                      type="hidden"
                                                      {...register(
                                                        "appearance",
                                                        {
                                                          required:
                                                            "Ce champ est requis",
                                                        }
                                                      )}
                                                    />

                                                    <div>
                                                      Mon choix : {color}{" "}
                                                    </div>
                                                    {errors.appearance && (
                                                      <p className="error-message">
                                                        {
                                                          errors.appearance
                                                            .message
                                                        }
                                                      </p>
                                                    )}
                                                  </div>
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="buttonsTastingNoteFormArea">
          {page > -1 && (
            <button type="button" onClick={prevPage}>
              <AiOutlineArrowLeft />
            </button>
          )}
          {page < fields.length - 1 && (
            <button type="button" onClick={nextPage}>
              <AiOutlineArrowRight />
            </button>
          )}
          {page === fields.length - 1 && (
            <button type="button" onClick={handleSubmit(onSubmit)}>
              <AiOutlineCheck />
            </button>
          )}
        </div>
      </form>
    </body>
  );
}

export default TastingNoteForm;
