import "./HopAromaWheel.css";
import React, { useState } from "react";

function HopAromaWheel({ onSelect, props }) {
  const [selectedColors, setSelectedColors] = useState([]);

  function isSelected(currentLevel) {
    const aromaRegex = /(\D+)(\d+)/;
    const matches = currentLevel.match(aromaRegex);

    if (!matches) return false;

    const aromaType = matches[1].toUpperCase();
    const currentLevelNumber = parseInt(matches[2]);

    return selectedColors.some((selectedColor) => {
      const selectedMatches = selectedColor.match(aromaRegex);
      if (!selectedMatches) return false;

      const selectedAromaType = selectedMatches[1].toUpperCase();
      const selectedLevelNumber = parseInt(selectedMatches[2]);

      return (
        aromaType === selectedAromaType &&
        currentLevelNumber <= selectedLevelNumber
      );
    });
  }

  const handleSegmentClick = (event) => {
    const clickedElementId = event.target.id;

    // Extraire l'arôme et le niveau de l'élément cliqué
    const aromaRegex = /(\D+)(\d+)/;
    const matches = clickedElementId.match(aromaRegex);
    if (!matches) return;

    const aromaType = matches[1].toUpperCase();
    const currentLevelNumber = parseInt(matches[2]);

    // Mise à jour de selectedColors
    let newSelectedColors = selectedColors.filter((selectedColor) => {
      const selectedMatches = selectedColor.match(aromaRegex);
      if (!selectedMatches) return false;

      const selectedAromaType = selectedMatches[1].toUpperCase();
      const selectedLevelNumber = parseInt(selectedMatches[2]);

      // Conserver uniquement les arômes différents ou les niveaux inférieurs du même arôme
      return (
        selectedAromaType !== aromaType ||
        selectedLevelNumber < currentLevelNumber
      );
    });

    // Vérifier si le niveau actuel est déjà sélectionné
    if (!newSelectedColors.includes(clickedElementId)) {
      newSelectedColors.push(clickedElementId);
    }

    setSelectedColors(newSelectedColors);

    if (onSelect) {
      onSelect(newSelectedColors);
    }

    // Afficher les arômes sélectionnés dans la console
    console.log("Arômes sélectionnés:", newSelectedColors);
  };

  return (
    <svg
      id="CIBLE_HOUBLON"
      data-name="CIBLE HOUBLON"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 270.1462 262.7811"
      {...props}
      onClick={handleSegmentClick}
    >
      <defs>
        <style>
          {
            ".a18874ef-fea2-4b7d-a472-f60ed60ee935{fill:none;stroke:#79481d;stroke-miterlimit:10}.a5d3611b-e6a5-4f94-96a1-0424f1d04351{fill:#1d1d1b}"
          }
        </style>
      </defs>
      <g id="POLICES" className="TEXTEWHEEL">
        <g id="AGRUMES">
          <path
            className="cls-1"
            d="M199.0635,348.7368l2.8369,4.05-1.9541,2.4858-9.8418-14.5771,2.4092-3.062L209.04,343.71l-2.03,2.5815-4.6573-1.7358Zm1.1592-5.2134-4.06-1.5136c-1.0137-.3882-2.08-.8755-3-1.2769l-.0342.043c.6211.7988,1.374,1.7168,1.9736,2.5654l2.4532,3.5737Z"
            transform="translate(-162.3438 -295.3283)"
          />
          <path
            className="cls-1"
            d="M208.9717,326.7227c.5371.6152,1.2168,1.3544,2.3887,2.5214l4.8613,4.8389c1.7969,1.7876,2.7109,3.4224,2.6211,5.1338a6.951,6.951,0,0,1-2.2578,4.3511,8.7359,8.7359,0,0,1-3.6465,2.3066l-1.1875-2.1113a7.1524,7.1524,0,0,0,3.1494-1.8413c1.4014-1.4077,1.706-3.2036-.1377-5.04l-.7324-.728-.0352.0352a4.0647,4.0647,0,0,1-1.2275,3.747c-2.1133,2.1231-5.3819,1.8819-7.7988-.5249-2.8272-2.8139-2.7061-6.334-.669-8.3818a3.8067,3.8067,0,0,1,3.8145-1.1646l.0351-.0351-1.0615-1.2134Zm1.2109,5.66a3.15,3.15,0,0,0-.6514-.5132,2.5468,2.5468,0,0,0-3.1562.3838c-1.1943,1.2007-1.0439,3.1514.7061,4.8926,1.456,1.4483,3.37,1.89,4.7236.53a2.5753,2.5753,0,0,0,.4287-3.0576,3.9027,3.9027,0,0,0-.6641-.8545Z"
            transform="translate(-162.3438 -295.3283)"
          />
          <path
            className="cls-1"
            d="M214.0771,327.4761c-1.0429-1.2412-1.8339-2.1319-2.5761-2.9156l2.0293-1.7021,1.5644,1.689.0664-.0552a3.6032,3.6032,0,0,1,.8828-4.0982,2.9577,2.9577,0,0,1,.5938-.4106l1.85,2.207a4.1906,4.1906,0,0,0-.7422.51,2.6935,2.6935,0,0,0-.6133,3.5342,4.9871,4.9871,0,0,0,.5323.7568l4.03,4.8062-2.3447,1.9668Z"
            transform="translate(-162.3438 -295.3283)"
          />
          <path
            className="cls-1"
            d="M232.3174,319.4736c.8135,1.1836,1.5312,2.15,2.1338,2.93l-2.2022,1.5132-1.1582-1.4233-.0586.041A4.3813,4.3813,0,0,1,229.03,326.46c-1.8184,1.2485-4.2227,1.2929-6.459-1.9639l-4.0166-5.8457,2.5224-1.7334,3.7266,5.4253c1.1377,1.6553,2.3906,2.3569,3.7266,1.439a2.5384,2.5384,0,0,0,.998-2.669,2.6269,2.6269,0,0,0-.3984-.873l-4.1328-6.0152,2.5234-1.7329Z"
            transform="translate(-162.3438 -295.3283)"
          />
          <path
            className="cls-1"
            d="M232.293,313.4946c-.6338-1.2441-1.1953-2.2509-1.72-3.1587l2.3262-1.185.9307,1.518.0625-.0317a4.1643,4.1643,0,0,1,2.3877-3.5146,3.4734,3.4734,0,0,1,4.0009.4638l.044-.0224a4.97,4.97,0,0,1,.6269-2.0244,4.19,4.19,0,0,1,1.8682-1.7491c1.78-.9067,4.2158-.6118,5.9766,2.8428l3.23,6.3418-2.6572,1.3535L246.335,308.37c-.9141-1.7959-2.0733-2.5327-3.3731-1.87a2.2958,2.2958,0,0,0-1.164,2.3979,3.4458,3.4458,0,0,0,.3261.9805l3.292,6.4644-2.6562,1.353-3.1739-6.23c-.7724-1.5171-1.9189-2.269-3.1718-1.6309a2.424,2.424,0,0,0-1.1768,2.5371,3.1343,3.1343,0,0,0,.3242.97l3.2442,6.3681-2.6563,1.3531Z"
            transform="translate(-162.3438 -295.3283)"
          />
          <path
            className="cls-1"
            d="M254.4043,306.7739c.8018,2.0415,2.7187,2.3838,4.5654,1.7368a9.2039,9.2039,0,0,0,2.9649-1.6333l1.123,1.8365a10.938,10.938,0,0,1-3.7344,2.1225c-3.705,1.2989-6.6777-.23-7.9023-3.726-1.1035-3.15-.2393-6.7989,3.4316-8.0855,3.709-1.3008,5.9854,1.3257,6.8614,3.8262a6.4593,6.4593,0,0,1,.3232,1.247Zm4.3135-3.77c-.3584-1.0615-1.4219-2.624-3.3555-1.9463a2.9925,2.9925,0,0,0-1.668,3.7075Z"
            transform="translate(-162.3438 -295.3283)"
          />
          <path
            className="cls-1"
            d="M264.709,305.8745a6.707,6.707,0,0,0,3.207.1079c1.3057-.3266,1.751-1.0195,1.5576-1.789-.1972-.795-.7822-1.0772-2.3535-1.2286-2.4931-.2124-3.7617-1.2231-4.1-2.6152-.5156-2.063.7969-4.1,3.5352-4.7842a6.9709,6.9709,0,0,1,3.2861-.0771l-.0674,2.229a5.4974,5.4974,0,0,0-2.65-.0537c-1.0547.2632-1.5127.9316-1.332,1.6528.1865.7446.8418.9609,2.459,1.1309,2.3242.2207,3.581,1.0634,4.0234,2.7905.5352,2.1392-.7451,4.0991-3.8428,4.874a7.8378,7.8378,0,0,1-3.78.0713Z"
            transform="translate(-162.3438 -295.3283)"
          />
        </g>
        <g id="EPICES">
          <path
            className="cls-1"
            d="M353.03,309.4058l-5.7735-2.543-1.98,4.4941,6.455,2.8443-1.0136,2.3022-9.2559-4.0776,6.75-15.3208,8.918,3.9282-1.0147,2.3027-6.1181-2.6953-1.7354,3.939,5.7744,2.5434Z"
            transform="translate(-162.3438 -295.3283)"
          />
          <path
            className="cls-1"
            d="M356.2793,310.2646c.7832-1.3579,1.3955-2.5083,1.917-3.5214l2.3262,1.3422-.7979,1.6841.042.0249a4.69,4.69,0,0,1,4.6563.2256c2.4013,1.3858,3.207,4.5928,1.32,7.8623-2.1914,3.7989-5.62,4.25-8.0059,2.8731a3.9288,3.9288,0,0,1-2.1377-3.0967l-.043-.0254-3.1279,5.4219-2.6484-1.5274Zm1.1367,4.146a3.8126,3.8126,0,0,0-.3437.7637,2.774,2.774,0,0,0,1.2568,3.1944c1.6816.97,3.4717.1425,4.6582-1.9136,1.07-1.855,1.0381-3.8823-.6641-4.8653a2.8642,2.8642,0,0,0-3.497.5977,3.16,3.16,0,0,0-.48.6118Z"
            transform="translate(-162.3438 -295.3283)"
          />
          <path
            className="cls-1"
            d="M364.0859,324.4219l6.89-9.9365,2.5146,1.7431-6.89,9.937Zm11.48-10.8726a1.6223,1.6223,0,0,1-2.3711.3623,1.6525,1.6525,0,1,1,2.3711-.3623Z"
            transform="translate(-162.3438 -295.3283)"
          />
          <path
            className="cls-1"
            d="M375.9775,332.9844a8.6767,8.6767,0,0,1-3.0566-1.647,5.8212,5.8212,0,0,1-.998-8.727c2.2636-2.8267,5.955-3.4761,9.2753-.8165a7.9455,7.9455,0,0,1,1.961,2.2779l-1.8613,1.4551a5.1644,5.1644,0,0,0-1.5108-1.815,3.9178,3.9178,0,0,0-4.8838,6.1133,5.9572,5.9572,0,0,0,2.1719,1.1294Z"
            transform="translate(-162.3438 -295.3283)"
          />
          <path
            className="cls-1"
            d="M381.8486,331.8687c-1.4638,1.6318-.9111,3.4985.4961,4.8588a9.2119,9.2119,0,0,0,2.7871,1.9175l-1.1367,1.8267a10.93,10.93,0,0,1-3.5713-2.3858c-2.8213-2.729-2.7871-6.0708-.2119-8.7338,2.3193-2.398,5.97-3.26,8.7647-.5567,2.8252,2.7319,1.497,5.9439-.3448,7.8482a6.3836,6.3836,0,0,1-.9687.8476Zm5.3037,2.1655c.7881-.7964,1.7081-2.4478.2373-3.8711a2.9932,2.9932,0,0,0-4.0625.17Z"
            transform="translate(-162.3438 -295.3283)"
          />
          <path
            className="cls-1"
            d="M387.2646,340.6655a6.7021,6.7021,0,0,0,1.3458,2.9136c.8769,1.0205,1.6972,1.1069,2.2988.5894.6211-.5337.6094-1.1831.04-2.6548-.9306-2.3228-.5967-3.9107.4951-4.8374,1.6114-1.3872,4.0215-1.1294,5.8623,1.0088a6.9644,6.9644,0,0,1,1.5459,2.9018l-2.0224.9414a5.505,5.505,0,0,0-1.1416-2.393c-.711-.8238-1.5117-.9327-2.0762-.4473-.582.501-.4805,1.1831.0938,2.7051.8466,2.1757.6582,3.6777-.6856,4.8486-1.6719,1.439-3.998,1.1753-6.082-1.2446a7.84,7.84,0,0,1-1.7617-3.3452Z"
            transform="translate(-162.3438 -295.3283)"
          />
        </g>
        <g id="FLEURS">
          <path
            className="cls-1"
            d="M397.7217,486.7969l4.9-8.3487,2.17,1.2735-3.3525,5.71,3.9853,2.3379,3.1172-5.31,2.15,1.2627-3.1172,5.3105,6.1348,3.6-1.5489,2.64Z"
            transform="translate(-162.3438 -295.3283)"
          />
          <path
            className="cls-1"
            d="M402.5889,476.3525l1.3554-2.7431,15.8116,7.8086-1.3565,2.7431Z"
            transform="translate(-162.3438 -295.3283)"
          />
          <path
            className="cls-1"
            d="M417.4619,473.916c2.0645.7422,3.586-.4746,4.3164-2.29a9.2019,9.2019,0,0,0,.7246-3.3047l2.1211.3642a10.9052,10.9052,0,0,1-.8652,4.2071c-1.4639,3.6426-4.5723,4.8711-8.01,3.4883-3.0957-1.2461-5.2715-4.3018-3.8193-7.91,1.4658-3.6465,4.9424-3.6269,7.4-2.6387a6.3507,6.3507,0,0,1,1.15.5791Zm.0078-5.7295c-1.0332-.4306-2.91-.66-3.6738,1.24a2.991,2.991,0,0,0,1.6875,3.6982Z"
            transform="translate(-162.3438 -295.3283)"
          />
          <path
            className="cls-1"
            d="M425.8984,453.6914c1.38.3936,2.55.68,3.5108.8965l-.7324,2.5693L426.88,456.8l-.0195.0674a4.3889,4.3889,0,0,1,.9609,4.3008c-.6035,2.1191-2.4258,3.6855-6.2246,2.6054l-6.82-1.9414.8379-2.9414,6.3271,1.8c1.9326.55,3.3428.29,3.7871-1.2666a2.5378,2.5378,0,0,0-.9326-2.6924,2.6342,2.6342,0,0,0-.8633-.4179l-7.0176-1.9951.8379-2.9434Z"
            transform="translate(-162.3438 -295.3283)"
          />
          <path
            className="cls-1"
            d="M422.1475,449.1582c-1.5909-.3018-2.7676-.49-3.8379-.625l.4922-2.6016,2.2783.3155.0156-.084a3.6056,3.6056,0,0,1-1.91-3.7295,2.9478,2.9478,0,0,1,.1983-.6924l2.8291.5361a4.13,4.13,0,0,0-.252.8633,2.6953,2.6953,0,0,0,1.7627,3.1231,5.0519,5.0519,0,0,0,.8907.247l6.16,1.167-.5683,3.0049Z"
            transform="translate(-162.3438 -295.3283)"
          />
          <path
            className="cls-1"
            d="M429.0381,441.1934a6.71,6.71,0,0,0,1.2217-2.9668c.1494-1.336-.3448-1.9961-1.1319-2.084-.8134-.0918-1.2812.3574-1.9726,1.7754-1.07,2.26-2.459,3.0976-3.8819,2.9267-2.1132-.2373-3.5615-2.1787-3.247-4.9814a6.969,6.969,0,0,1,1.0752-3.1055l2.0654.8418a5.4776,5.4776,0,0,0-.9766,2.4629c-.123,1.08.3438,1.7422,1.084,1.8252.7617.0859,1.1934-.4531,1.917-1.9082,1.0186-2.1,2.2471-2.9834,4.0186-2.794,2.1914.2461,3.58,2.129,3.2246,5.3028a7.8469,7.8469,0,0,1-1.253,3.5654Z"
            transform="translate(-162.3438 -295.3283)"
          />
        </g>
        <g id="HERBES">
          <path
            className="cls-1"
            d="M270.3193,538.1914l-1.3545,6.5606,6.93,1.43,1.3535-6.5595,2.9971.6181L276.86,556.6387l-2.9961-.6182,1.4931-7.2363-6.93-1.4307-1.4941,7.2363-2.9971-.6181,3.3848-16.3985Z"
            transform="translate(-162.3438 -295.3283)"
          />
          <path
            className="cls-1"
            d="M283.73,552.168c-.1133,2.1914,1.4912,3.2959,3.4414,3.4687a9.1823,9.1823,0,0,0,3.373-.2617l.2647,2.1367a10.9589,10.9589,0,0,1-4.2783.3887c-3.91-.3477-5.9864-2.9668-5.6563-6.6582.2949-3.3242,2.5918-6.291,6.4658-5.9453,3.9161.3486,4.9034,3.6806,4.669,6.32a6.4617,6.4617,0,0,1-.2217,1.2695Zm5.4863-1.65c.1123-1.1143-.2109-2.9766-2.25-3.1582a2.9936,2.9936,0,0,0-3.0527,2.6855Z"
            transform="translate(-162.3438 -295.3283)"
          />
          <path
            className="cls-1"
            d="M294.6592,549.8906c.0049-1.6191-.0244-2.81-.086-3.8886l2.6485.01.1054,2.2988h.086a3.6075,3.6075,0,0,1,3.32-2.5605,2.8788,2.8788,0,0,1,.7187.07l-.0107,2.8789a4.2074,4.2074,0,0,0-.8965-.09,2.6993,2.6993,0,0,0-2.751,2.3047,5.131,5.131,0,0,0-.081.9218l-.0215,6.2735-3.06-.01Z"
            transform="translate(-162.3438 -295.3283)"
          />
          <path
            className="cls-1"
            d="M304.1494,558.084c-.0176-.8154-.0918-2.1524-.2012-3.3867l-1.26-14.1885,3.0429-.2695.6377,7.1787.05-.0039a4.33,4.33,0,0,1,3.6749-2.2715c2.9374-.2617,5.2578,1.9863,5.5624,5.6582.3838,4.33-2.1679,6.7285-4.8945,6.9707a4.1367,4.1367,0,0,1-3.98-1.7481l-.0556.0049.0244,1.8252Zm2.7-5.2637a3.2672,3.2672,0,0,0,.1406.7109,2.7868,2.7868,0,0,0,2.877,1.8946c1.9092-.17,2.9287-1.792,2.7119-4.2246-.1875-2.1192-1.3652-3.7715-3.374-3.5938a2.8419,2.8419,0,0,0-2.501,2.4649,3.5434,3.5434,0,0,0-.0283.7988Z"
            transform="translate(-162.3438 -295.3283)"
          />
          <path
            className="cls-1"
            d="M320.7412,550.91c.51,2.1328,2.3594,2.74,4.2793,2.3564a9.2141,9.2141,0,0,0,3.1631-1.2031l.8564,1.9756a10.96,10.96,0,0,1-3.9941,1.58c-3.8506.77-6.5811-1.1591-7.3066-4.7929-.6543-3.2735.7128-6.7676,4.5273-7.5293,3.8555-.77,5.7422,2.15,6.2617,4.748a6.444,6.444,0,0,1,.1465,1.28Zm4.7969-3.1329c-.2051-1.1005-1.041-2.7968-3.0488-2.3955a2.9922,2.9922,0,0,0-2.17,3.4385Z"
            transform="translate(-162.3438 -295.3283)"
          />
          <path
            className="cls-1"
            d="M331.99,550.877a6.695,6.695,0,0,0,3.21-.0332c1.29-.3829,1.7051-1.0958,1.4785-1.8555-.2334-.7852-.83-1.042-2.4062-1.125-2.5-.1026-3.8125-1.0567-4.211-2.4326-.6064-2.04.6153-4.1319,3.3213-4.9366a6.9674,6.9674,0,0,1,3.2822-.2207l.0293,2.2305a5.4779,5.4779,0,0,0-2.65.0615c-1.0429.31-1.4707.9971-1.26,1.71.2187.7363.8847.9238,2.5078,1.0234,2.332.1182,3.625.9053,4.1445,2.61.628,2.1142-.5664,4.13-3.6269,5.04a7.8247,7.8247,0,0,1-3.7744.2383Z"
            transform="translate(-162.3438 -295.3283)"
          />
        </g>
        <g id="RESINES">
          <path
            className="cls-1"
            d="M178.8516,420.2065a27.6058,27.6058,0,0,1,.4609,4.6294c.0518,2.3985-.3174,4.045-1.2373,5.1914a4.2171,4.2171,0,0,1-3.33,1.5157,4.4271,4.4271,0,0,1-4.2471-2.9532h-.0712c-.4327,1.2237-1.57,1.9551-3.1885,2.4053a37.266,37.266,0,0,0-4.6367,1.4229l-.0684-3.1387a21.6342,21.6342,0,0,1,3.9248-1.2139c2.1719-.5283,2.8613-1.3369,2.8633-3.0752l-.0332-1.539L162.41,423.6l-.0664-3.0342Zm-7.3213,3.1958.039,1.8086c.045,2.0743,1.1661,3.3272,2.83,3.2911,1.82-.04,2.6485-1.3418,2.6133-3.3184a8.531,8.531,0,0,0-.1973-1.8965Z"
            transform="translate(-162.3438 -295.3283)"
          />
          <path
            className="cls-1"
            d="M168.3721,437.0176c-2.1641.3623-2.8955,2.1679-2.6446,4.1084a9.2331,9.2331,0,0,0,.9844,3.2383l-2.0293.7187A10.9265,10.9265,0,0,1,163.38,440.99c-.5039-3.8935,1.6055-6.4853,5.2812-6.9609,3.31-.4287,6.7012,1.1738,7.2012,5.0312.5039,3.8985-2.5371,5.5821-5.165,5.9219a6.4636,6.4636,0,0,1-1.2871.0586Zm2.7968,5.0019c1.1114-.1308,2.8614-.8486,2.5987-2.88a2.993,2.993,0,0,0-3.2822-2.3994Zm10.1016.0782-4.0342-2.5372-.2724-2.1015,3.9218,1.666Z"
            transform="translate(-162.3438 -295.3283)"
          />
          <path
            className="cls-1"
            d="M167.6338,448.2236a6.7017,6.7017,0,0,0-.1856,3.2041c.294,1.3125.9756,1.7754,1.75,1.6016.7989-.18,1.0957-.7568,1.2852-2.3232.2734-2.4873,1.3145-3.7315,2.7148-4.0342,2.0762-.4649,4.0791.8965,4.6973,3.65a6.9773,6.9773,0,0,1-.0039,3.2871l-2.2266-.1211a5.5024,5.5024,0,0,0,.1192-2.6485c-.2383-1.0605-.8946-1.5351-1.6211-1.372-.7481.1679-.98.8174-1.19,2.4306-.2784,2.3174-1.1514,3.5528-2.8877,3.9541-2.1524.4815-4.08-.8457-4.7793-3.9619a7.84,7.84,0,0,1,.0214-3.78Z"
            transform="translate(-162.3438 -295.3283)"
          />
          <path
            className="cls-1"
            d="M167.5039,459.6143l11.5947-3.42.8653,2.9336L168.37,462.5488Zm15.7568-1.2715a1.622,1.622,0,0,1-2.0742-1.2041,1.6521,1.6521,0,1,1,2.0742,1.2041Z"
            transform="translate(-162.3438 -295.3283)"
          />
          <path
            className="cls-1"
            d="M177.4189,463.291c1.3028-.5029,2.3614-.958,3.3184-1.3867l.9609,2.4922-1.64.793.0264.0664a4.4058,4.4058,0,0,1,3.3359,2.8418c.7578,1.9677.28,4.499-3.2871,5.875l-6.7,2.5859-1.1006-2.8535,6.3633-2.4561c1.6191-.624,2.6221-1.6943,2.02-3.2519a2.5371,2.5371,0,0,0-2.5293-1.5733,3.0094,3.0094,0,0,0-.9561.2188l-6.6357,2.56-1.1025-2.8535Z"
            transform="translate(-162.3438 -295.3283)"
          />
          <path
            className="cls-1"
            d="M180.7832,479.873c-1.9111,1.0752-1.9853,3.0215-1.09,4.7608a9.1838,9.1838,0,0,0,2.0244,2.709l-1.6631,1.3662a10.9369,10.9369,0,0,1-2.6162-3.4043c-1.7969-3.49-.6934-6.6445,2.6006-8.3408,2.9658-1.5274,6.7-1.1729,8.48,2.2841,1.7988,3.4942-.4874,6.1094-2.8428,7.3223a6.3049,6.3049,0,0,1-1.19.4922Zm4.3272,3.751c1.0029-.501,2.4042-1.77,1.4658-3.5908a2.9926,2.9926,0,0,0-3.9014-1.1406Z"
            transform="translate(-162.3438 -295.3283)"
          />
          <path
            className="cls-1"
            d="M183.9033,490.6758a6.73,6.73,0,0,0,.91,3.0781c.7226,1.1348,1.5215,1.3389,2.1914.9141.6914-.44.7734-1.084.4219-2.6231-.586-2.4316-.0274-3.9551,1.1875-4.7158,1.7949-1.1416,4.1425-.5391,5.6562,1.8428a6.9729,6.9729,0,0,1,1.1113,3.0947l-2.1367.64a5.4935,5.4935,0,0,0-.7851-2.5312c-.584-.918-1.3623-1.1416-1.99-.7422-.6474.4121-.6465,1.1016-.2968,2.6914.5244,2.2735.121,3.7344-1.377,4.6992-1.8613,1.1836-4.126.5879-5.84-2.1074a7.8428,7.8428,0,0,1-1.2618-3.5644Z"
            transform="translate(-162.3438 -295.3283)"
          />
        </g>
      </g>

      <g id="RESINES" data-name="RESINES" className="BASE">
        <path
          id="RESINES5"
          className={`RESINES5 ${isSelected("RESINES5") ? "selectedHop" : ""}`}
          d="M247.79,492.22,234.7,510.24a107.9116,107.9116,0,0,1-39.74-120.15l21.19,6.89a85.5809,85.5809,0,0,0,31.64,95.24Z"
          transform="translate(-162.3438 -295.3283)"
        />
        <path
          id="RESINES4"
          className={`RESINES4 ${isSelected("RESINES4") ? "selectedHop" : ""}`}
          d="M260.87,474.22l-13.08,18a85.5809,85.5809,0,0,1-31.64-95.24l21.16,6.88a63.393,63.393,0,0,0,23.56,70.36Z"
          transform="translate(-162.3438 -295.3283)"
        />
        <path
          id="RESINES3"
          className={`RESINES3 ${isSelected("RESINES3") ? "selectedHop" : ""}`}
          d="M273.96,456.2l-13.09,18.02a63.393,63.393,0,0,1-23.56-70.36l21.19,6.88a41.0857,41.0857,0,0,0,15.46,45.46Z"
          transform="translate(-162.3438 -295.3283)"
        />
        <path
          id="RESINES2"
          className={`RESINES2 ${isSelected("RESINES2") ? "selectedHop" : ""}`}
          d="M287.05,438.18,273.96,456.2a41.0857,41.0857,0,0,1-15.46-45.46l21.19,6.89a18.7289,18.7289,0,0,0,7.36,20.55Z"
          transform="translate(-162.3438 -295.3283)"
        />
        <path
          id="RESINES1"
          className={`RESINES1 ${isSelected("RESINES1") ? "selectedHop" : ""}`}
          d="M297.72,423.49l-10.67,14.69a18.7289,18.7289,0,0,1-7.36-20.55Z"
          transform="translate(-162.3438 -295.3283)"
        />
      </g>
      <g id="HERBES" data-name="HERBES" className="BASE">
        <path
          id="HERBES5"
          className={`HERBES5 ${isSelected("HERBES5") ? "selectedHop" : ""}`}
          d="M360.74,510.24a107.7983,107.7983,0,0,1-126.04,0l13.09-18.02a85.5372,85.5372,0,0,0,99.86.01h.01Z"
          transform="translate(-162.3438 -295.3283)"
        />
        <path
          id="HERBES4"
          className={`HERBES4 ${isSelected("HERBES4") ? "selectedHop" : ""}`}
          d="M347.65,492.23a85.5372,85.5372,0,0,1-99.86-.01l13.08-18a63.3237,63.3237,0,0,0,73.71,0Z"
          transform="translate(-162.3438 -295.3283)"
        />
        <path
          id="HERBES3"
          className={`HERBES3 ${isSelected("HERBES3") ? "selectedHop" : ""}`}
          d="M334.58,474.22a63.3237,63.3237,0,0,1-73.71,0l13.09-18.02a41.0286,41.0286,0,0,0,47.52,0Z"
          transform="translate(-162.3438 -295.3283)"
        />
        <path
          id="HERBES2"
          className={`HERBES2 ${isSelected("HERBES2") ? "selectedHop" : ""}`}
          d="M321.48,456.2a41.0286,41.0286,0,0,1-47.52,0l13.09-18.02a18.7523,18.7523,0,0,0,21.35.01Z"
          transform="translate(-162.3438 -295.3283)"
        />
        <path
          id="HERBES1"
          className={`HERBES1 ${isSelected("HERBES1") ? "selectedHop" : ""}`}
          d="M308.4,438.19a18.7523,18.7523,0,0,1-21.35-.01l10.67-14.69Z"
          transform="translate(-162.3438 -295.3283)"
        />
      </g>
      <g id="FLEURS" data-name="FLEURS" className="BASE">
        <path
          id="FLEURS5"
          className={`FLEURS5 ${isSelected("FLEURS5") ? "selectedHop" : ""}`}
          d="M405.54,422.77a107.6415,107.6415,0,0,1-44.79,87.47h-.01l-13.08-18.01a85.6434,85.6434,0,0,0,31.65-95.25l21.18-6.89A107.6224,107.6224,0,0,1,405.54,422.77Z"
          transform="translate(-162.3438 -295.3283)"
        />
        <path
          id="FLEURS4"
          className={`FLEURS4 ${isSelected("FLEURS4") ? "selectedHop" : ""}`}
          d="M383.27,422.77a85.4389,85.4389,0,0,1-35.61,69.46l-13.08-18.01a63.3449,63.3449,0,0,0,23.55-70.36l21.18-6.88A85.4567,85.4567,0,0,1,383.27,422.77Z"
          transform="translate(-162.3438 -295.3283)"
        />
        <path
          id="FLEURS3"
          className={`FLEURS3 ${isSelected("FLEURS3") ? "selectedHop" : ""}`}
          d="M361.01,422.77a63.2214,63.2214,0,0,1-26.43,51.45l-13.09-18.03a41.0557,41.0557,0,0,0,15.46-45.44l21.18-6.89A63.1543,63.1543,0,0,1,361.01,422.77Z"
          transform="translate(-162.3438 -295.3283)"
        />
        <path
          id="FLEURS2"
          className={`FLEURS2 ${isSelected("FLEURS2") ? "selectedHop" : ""}`}
          d="M338.74,422.77a40.9272,40.9272,0,0,1-17.25,33.42l-13.08-18.01a18.747,18.747,0,0,0,8.06-15.41,18.5545,18.5545,0,0,0-.72-5.14l21.2-6.88A41.13,41.13,0,0,1,338.74,422.77Z"
          transform="translate(-162.3438 -295.3283)"
        />
        <path
          id="FLEURS1"
          className={`FLEURS1 ${isSelected("FLEURS1") ? "selectedHop" : ""}`}
          d="M316.47,422.77a18.747,18.747,0,0,1-8.06,15.41l-10.67-14.69,18.01-5.86A18.5545,18.5545,0,0,1,316.47,422.77Z"
          transform="translate(-162.3438 -295.3283)"
        />
      </g>
      <g id="EPICES" data-name="EPICES" className="BASE">
        <path
          id="EPICES5"
          className={`EPICES5 ${isSelected("EPICES5") ? "selectedHop" : ""}`}
          d="M400.49,390.09l-21.18,6.89a85.5754,85.5754,0,0,0-81.59-59.76V314.96A107.8432,107.8432,0,0,1,400.49,390.09Z"
          transform="translate(-162.3438 -295.3283)"
        />
        <path
          id="EPICES4"
          className={`EPICES4 ${isSelected("EPICES4") ? "selectedHop" : ""}`}
          d="M379.31,396.98l-21.18,6.88a63.3157,63.3157,0,0,0-60.41-44.37V337.22A85.5754,85.5754,0,0,1,379.31,396.98Z"
          transform="translate(-162.3438 -295.3283)"
        />
        <path
          id="EPICES3"
          className={`EPICES3 ${isSelected("EPICES3") ? "selectedHop" : ""}`}
          d="M358.13,403.86l-21.18,6.89a41.0318,41.0318,0,0,0-39.23-29V359.49A63.3157,63.3157,0,0,1,358.13,403.86Z"
          transform="translate(-162.3438 -295.3283)"
        />
        <path
          id="EPICES2"
          className={`EPICES2 ${isSelected("EPICES2") ? "selectedHop" : ""}`}
          d="M336.95,410.75l-21.2,6.88a18.7355,18.7355,0,0,0-18.03-13.61V381.75A41.0318,41.0318,0,0,1,336.95,410.75Z"
          transform="translate(-162.3438 -295.3283)"
        />
        <path
          id="EPICES1"
          className={`EPICES1 ${isSelected("EPICES1") ? "selectedHop" : ""}`}
          d="M315.75,417.63l-18.01,5.86h-.02V404.02A18.7355,18.7355,0,0,1,315.75,417.63Z"
          transform="translate(-162.3438 -295.3283)"
        />
      </g>
      <g id="AGRUMES" data-name="AGRUMES" className="BASE">
        <path
          id="AGRUMES5"
          className={`AGRUMES5 ${isSelected("AGRUMES5") ? "selectedHop" : ""}`}
          d="M297.72,314.96v22.26a85.5506,85.5506,0,0,0-81.57,59.76l-21.19-6.89A107.8307,107.8307,0,0,1,297.72,314.96Z"
          transform="translate(-162.3438 -295.3283)"
        />
        <path
          id="AGRUMES4"
          className={`AGRUMES4 ${isSelected("AGRUMES4") ? "selectedHop" : ""}`}
          d="M297.72,337.22v22.27a63.3343,63.3343,0,0,0-60.41,44.37l-21.16-6.88A85.5506,85.5506,0,0,1,297.72,337.22Z"
          transform="translate(-162.3438 -295.3283)"
        />
        <path
          id="AGRUMES3"
          className={`AGRUMES3 ${isSelected("AGRUMES3") ? "selectedHop" : ""}`}
          d="M297.72,359.49v22.26a41.0322,41.0322,0,0,0-39.22,28.99l-21.19-6.88A63.3343,63.3343,0,0,1,297.72,359.49Z"
          transform="translate(-162.3438 -295.3283)"
        />
        <path
          id="AGRUMES2"
          className={`AGRUMES2 ${isSelected("AGRUMES2") ? "selectedHop" : ""}`}
          d="M297.72,381.75v22.27a18.7445,18.7445,0,0,0-18.03,13.61l-21.19-6.89A41.0322,41.0322,0,0,1,297.72,381.75Z"
          transform="translate(-162.3438 -295.3283)"
        />
        <path
          id="AGRUMES1"
          className={`AGRUMES1 ${isSelected("AGRUMES1") ? "selectedHop" : ""}`}
          d="M297.72,404.02v19.47l-18.03-5.86A18.7445,18.7445,0,0,1,297.72,404.02Z"
          transform="translate(-162.3438 -295.3283)"
        />
      </g>
      <g id="HOUBLON" className="HOUBLON">
        <path
          className="cls-1"
          d="M214.9858,406.7124v11.375h12.0166v-11.375H232.2v28.436h-5.1973V422.6006H214.9858v12.5478h-5.1972v-28.436Z"
          transform="translate(-162.3438 -295.3283)"
        />
        <path
          className="cls-1"
          d="M263.3853,420.646c0,9.522-5.7725,14.9663-13.6075,14.9663-8.0312,0-13.1181-6.1357-13.1181-14.4629,0-8.73,5.5171-14.9009,13.5381-14.9009C258.4927,406.2485,263.3853,412.5171,263.3853,420.646Zm-21.24.3979c0,5.8018,2.9312,10.4131,7.897,10.4131,5.0156,0,7.8574-4.67,7.8574-10.6186,0-5.3667-2.6621-10.4341-7.8447-10.4341C244.9131,410.4043,242.1455,415.2036,242.1455,421.0439Z"
          transform="translate(-162.3438 -295.3283)"
        />
        <path
          className="cls-1"
          d="M273.0044,406.7124V423.291c0,5.5567,2.3057,8.1455,5.8262,8.1455,3.7412,0,5.99-2.5635,5.99-8.1455V406.7124h5.1973v16.2437c0,8.7587-4.5069,12.6562-11.375,12.6562-6.5606,0-10.836-3.6807-10.836-12.6269v-16.273Z"
          transform="translate(-162.3438 -295.3283)"
        />
        <path
          className="cls-1"
          d="M295.9331,407.1025a43.4,43.4,0,0,1,7.5815-.6113c3.8145,0,6.2.4937,8.1387,1.8057a6.0648,6.0648,0,0,1,2.9326,5.35c0,2.6123-1.63,4.998-4.7314,6.1289v.11a7.182,7.182,0,0,1,5.7549,7.0434,7.4839,7.4839,0,0,1-2.7217,5.87c-2.0273,1.7491-5.3565,2.625-10.5664,2.625a49.128,49.128,0,0,1-6.3882-.373Zm5.1548,11.231h2.6611c3.6035,0,5.6055-1.6982,5.6055-4.1016,0-2.6709-2.0254-3.8984-5.2959-3.8984a14.158,14.158,0,0,0-2.9707.228Zm0,13.0405a16.2566,16.2566,0,0,0,2.748.1475c3.3223,0,6.3116-1.249,6.3116-4.7578,0-3.3047-2.9082-4.66-6.4756-4.6641h-2.584Z"
          transform="translate(-162.3438 -295.3283)"
        />
        <path
          className="cls-1"
          d="M320.2314,406.7124h5.1973V430.79h11.6934v4.3584H320.2314Z"
          transform="translate(-162.3438 -295.3283)"
        />
        <path
          className="cls-1"
          d="M364.6318,420.646c0,9.522-5.7724,14.9663-13.6074,14.9663-8.0312,0-13.1182-6.1357-13.1182-14.4629,0-8.73,5.5167-14.9009,13.5381-14.9009C359.7393,406.2485,364.6318,412.5171,364.6318,420.646Zm-21.2392.3979c0,5.8018,2.9306,10.4131,7.8965,10.4131,5.0156,0,7.8574-4.67,7.8574-10.6186,0-5.3667-2.6621-10.4341-7.8447-10.4341C346.1592,410.4043,343.3926,415.2036,343.3926,421.0439Z"
          transform="translate(-162.3438 -295.3283)"
        />
        <path
          className="cls-1"
          d="M369.084,435.1484v-28.436h5.9365l7.3457,12.2046a79.7136,79.7136,0,0,1,4.8389,9.5312l.1025-.0273c-.3672-3.7764-.4648-7.439-.4648-11.7852v-9.9233h4.8027v28.436h-5.3828l-7.42-12.5088a97.5982,97.5982,0,0,1-5.0987-9.81l-.1337.0273c.208,3.6939.2763,7.4488.2763,12.1187v10.1728Z"
          transform="translate(-162.3438 -295.3283)"
        />
      </g>
      <g id="CONTOURS_EXTERIEURS" data-name="CONTOURS EXTERIEURS">
        <g id="CONTOUR_BLANC" data-name="CONTOUR BLANC">
          <path
            className="cls-4"
            d="M297.647,314.9543q2.2629,0,4.5447.0943a107.8123,107.8123,0,0,1-4.3894,215.532q-2.2629,0-4.5448-.0943a107.8123,107.8123,0,0,1,4.3895-215.532m0-3a110.8124,110.8124,0,0,0-4.5138,221.5295c1.55.0642,3.121.0968,4.6692.0968a110.8123,110.8123,0,0,0,4.5137-221.5294c-1.55-.0643-3.1211-.0969-4.6691-.0969Z"
            transform="translate(-162.3438 -295.3283)"
            style={{
              fill: "#fff",
            }}
          />
        </g>
        <g id="CONTOUR_MARRON" data-name="CONTOUR MARRON">
          <path
            className="cls-5"
            d="M297.7246,316.956A105.8115,105.8115,0,1,1,191.9132,422.7675,105.9313,105.9313,0,0,1,297.7246,316.956m0-2A107.8115,107.8115,0,1,0,405.536,422.7675,107.8115,107.8115,0,0,0,297.7246,314.956Z"
            transform="translate(-162.3438 -295.3283)"
            style={{
              strokeWidth: 2,
              fill: "none",
              stroke: "#79481d",
              strokeMiterlimit: 10,
            }}
          />
        </g>
      </g>
    </svg>
  );
}
export default HopAromaWheel;
