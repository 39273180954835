import React from 'react';
import './StarRating.css';

const StarRating = ({ rating, onRatingChange }) => {

    const handleStarClick = (index) => {
        onRatingChange(index + 1);
    };

    return (
        <div className="star-rating_form">
            {[...Array(5)].map((_, index) => (
                <span 
                    key={index} 
                    className={`starform ${index < rating ? 'filled' : ''}`} 
                    onClick={() => handleStarClick(index)}
                >
                    ★
                </span>
            ))}
        </div>
    );
}

export default StarRating;
