import React from 'react';
import Lottie from 'lottie-react';
import Swal from 'sweetalert2';
import animationData from '../Information_custom_icon.json'; 
import './InformationIcon.css';

const InformationIconHopFlaveur = () => {

  const handleClick = () => {
    Swal.fire({
      title: 'Information',
      icon: 'info',
      html: `
      <strong>Agrumes</strong>: Cette flaveur rappelle vivement les fruits citriques comme le citron, l'orange, le pamplemousse ou la lime. Fréquemment rencontrée dans les bières de type IPA ou pale ale, elle donne une fraîcheur et une vivacité caractéristiques.<br>
      <strong>Épices</strong>: Les nuances épicées, souvent associées à des notes de poivre, de coriandre ou même de clou de girofle, peuvent être décelées dans certaines bières, notamment les bières belges ou de saison.<br>
      <strong>Fleurs</strong>: Ces flaveurs évoquent les fleurs fraîchement coupées, le parfum délicat du jasmin ou de la rose. On les trouve fréquemment dans les bières légères et aromatiques, apportant une délicate finesse.<br>
      <strong>Herbes</strong>: Une flaveur rappelant le caractère terreux de certaines herbes, comme le thym ou la sauge, souvent présente dans les bières de type Pilsner ou certaines lagers.<br>
      <strong>Résines</strong>: Cette flaveur dense évoque la résine de pin ou le caractère balsamique, typiquement rencontrée dans certaines IPAs ou bières fortement houblonnées, donnant un aspect collant et profond à la bouche.
    `,
      confirmButtonText: 'Compris!'
    });
    
  };

  return (
    <div className='icon' onClick={handleClick}>
      <Lottie animationData={animationData} />
    </div>
  );
};

export default InformationIconHopFlaveur;
