import React, { useState } from "react";
import "./SidebarMenu.css";
import { Link } from "react-router-dom";
import { FaRegWindowClose } from "react-icons/fa";
import { AiOutlineHome } from "react-icons/ai";
import { PiBeerStein } from "react-icons/pi";
import { CgProfile } from "react-icons/cg";
import { FiPlusCircle } from "react-icons/fi";
import { MdContentPasteSearch } from "react-icons/md";
import { SlMenu } from "react-icons/sl";

function SidebarMenu() {
  const [isOpen, setIsOpen] = useState(false);

  const closeSidebar = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className="sidebar" style={{ width: isOpen ? "250px" : "75px" }}>
        <FaRegWindowClose className="sidebar-close-button" style={{ display: isOpen ? "" : "none" }} onClick={closeSidebar} />
        <SlMenu className="sidebar-close-button" style={{ display: isOpen ? "none" : "" }} onClick={() => setIsOpen(true)} />
        <div className="sidebar-title">
          <Link to="/" onClick={() => setIsOpen(false)}>
            <AiOutlineHome className="SideBarIcons" />
            <l style={{ display: isOpen ? "" : "none" }}>Accueil</l>
          </Link>
          <Link to="/new" onClick={() => setIsOpen(false)}>
            <FiPlusCircle className="SideBarIcons" />
            <l style={{ display: isOpen ? "" : "none" }}>Ajouter</l>
          </Link>
          <Link to="/brewery" onClick={() => setIsOpen(false)}>
            <PiBeerStein className="SideBarIcons" />
            <l style={{ display: isOpen ? "" : "none" }}>Les brasseries</l>
          </Link>
          <div className="ProfilArea">
            <Link to="/Profil" onClick={() => setIsOpen(false)}>
              <CgProfile className="SideBarIcons" />
              <l style={{ display: isOpen ? "" : "none" }}>Mon Profil</l>
            </Link>
            <Link to="/MyTastingNotes" onClick={() => setIsOpen(false)}>
              <MdContentPasteSearch className="SideBarIcons" />
              <l style={{ display: isOpen ? "" : "none" }}>Mes dégustations</l>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default SidebarMenu;
