import React from 'react';
import Lottie from 'lottie-react';
import Swal from 'sweetalert2';
import animationData from '../Information_custom_icon.json'; 
import './InformationIcon.css';

const InformationIconMaltFlaveur = () => {

  const handleClick = () => {
    Swal.fire({
      title: 'Information',
      icon: 'info',
      html: `
      <strong>Café</strong>: Cette flaveur traduit un goût profond, rappelant une torréfaction intense du malt. Il peut varier entre un léger expresso jusqu'à un café noir robuste, dominant dans certaines bières foncées comme les stouts.<br>
      <strong>Caramel</strong>: Cette flaveur évoque le doux goût du sucre caramélisé, provenant souvent de malts spécifiquement traités. Elle peut varier d'un léger goût sucré à un goût riche de caramel fondu, typique des bières ambrées.<br>
      <strong>Chocolat</strong>: Une flaveur qui rappelle les nuances du cacao pur au chocolat au lait, souvent retrouvée dans des bières plus sombres comme les stouts et porters. Ce goût provient généralement des malts chocolatés.<br>
      <strong>Noisette</strong>: Cette flaveur dégage des notes douces de noix ou de noisettes grillées, présentes dans de nombreuses bières maltées, y compris certaines brown ales.<br>
      <strong>Céréales</strong>: Un goût fondamental dans la bière, cette flaveur rappelle les grains maltés sous leur forme la plus pure. Elle évoque le pain chaud, les biscuits et même certaines nuances de céréales grillées, fréquemment trouvées dans des bières claires comme les lagers.
    `,
    confirmButtonText: 'Compris!'
  });
    
  };

  return (
    <div className='icon' onClick={handleClick}>
      <Lottie animationData={animationData} />
    </div>
  );
};

export default InformationIconMaltFlaveur;
