import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

// Styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#f5f5f5',
    padding: 30,
  },
  section: {
    margin: 10,
    padding: 15,
    backgroundColor: '#fff',
    borderRadius: 10,
    border: '1px solid #ccc',
    boxShadow: '0px 3px 15px rgba(0,0,0,0.1)',
  },
  header: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 15,
    color: '#333',
  },
  subheader: {
    fontSize: 18,
    margin: 5,
    fontWeight: 'medium',
    color: '#555',
  },
  text: {
    fontSize: 14,
    margin: 5,
    color: '#333333',
  },
});

// Composant Document
const MyDocument = ({ beer }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.header}>Fiche de Dégustation</Text>
        <Text style={styles.text}>Nom de la bière: {beer.beerName}</Text>
        <Text style={styles.text}>Nom de la brasserie: {beer.breweryName}</Text>
        <Text style={styles.text}>Date de dégustation: {beer.tastingDate}</Text>
        <Text style={styles.text}>Apparence: {beer.appearance}</Text>
        <Text style={styles.text}>Mousse: {beer.foamAmount}</Text>
        <Text style={styles.text}>Arômes: {beer.aroma}</Text>
        <Text style={styles.text}>Odeur du houblon: {beer.hopSmell}</Text>
        <Text style={styles.text}>Goût du malt: {beer.maltTaste}</Text>
        <Text style={styles.text}>Goût du houblon: {beer.hopAroma}</Text>
        <Text style={styles.text}>Carbonation: {beer.carbonation}</Text>
        <Text style={styles.text}>Note de dégustation: {beer.rating}</Text>
        <Text style={styles.text}>Notes diverses: {beer.tastingNotes}</Text>
      </View>
    </Page>
  </Document>
);

export default MyDocument;
